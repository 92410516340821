<template>
  <div class="reservation-setting">
    <div class="wrap-content">
      <div class="fixed-display" v-if="checkPath">
        <div class="setting-title">予約設定</div>
        <div class="setting-tab-title-bar">
          <div v-for="titleObj in tabTitles" :key="titleObj.id">
            <base-setting-tab-title
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
      </div>
      <div class="content-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
export default {
  name: 'ReservationSetting',
  components: {
    BaseSettingTabTitle
  },
  data() {
    return {
      tabTitles: [
        {
          id: 1,
          title: '医院設定',
          link: 'base',
          names: ['reservation-setting-base']
        },
        {
          id: 2,
          title: '来院目的',
          link: 'reservation-purposes',
          names: ['reservation-purposes']
        },
        {
          id: 3,
          title: '受付時間',
          link: 'reservation-available-patterns',
          names: ['reservation-available-patterns']
        }
      ]
    }
  },
  computed: {
    checkPath() {
      if (
        this.$route.params.id ||
        this.$route.path ===
          '/settings/reservation-setting/reservation-purposes/new' ||
        this.$route.path ===
          '/settings/reservation-setting/reservation-available-patterns/new'
      ) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  > .wrap-content {
    > .fixed-display {
      position: sticky;
      position: -webkit-sticky;
      top: 24px;
      transform: translateY(-24px);
      padding-top: 24px;
      margin-bottom: -24px;
      z-index: 100;
      background-color: #{$white};
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
      }
      > .setting-tab-title-bar {
        display: flex;
        flex-direction: row;
        height: 26px;
        margin-top: 20px;
        padding-bottom: -4px;
        border-bottom: 1px solid;
        border-color: #{$light-grey};
        box-sizing: border-box;
      }
    }
    > .content-body {
      min-width: 614px;
    }
  }
}
</style>
