<template>
  <div class="calendar-table" data-e2e="calendar-table">
    <calendar-table-header
      :currentYear="currentYear"
      :currentMonth="currentMonth"
      @click="moveMonth"
    />
    <div class="calendar-body">
      <div class="calendar-body-area-week">
        <div
          v-for="week in weeks"
          class="calendar-body-area-day-week"
          :key="week"
        >
          <div class="calendar-body-area-day-week-text">{{ week }}</div>
        </div>
      </div>
      <div class="calendar-body-area-days">
        <div
          v-for="date in calendarMake"
          :key="date.id"
          class="calendar-body-area-day"
        >
          <calendar-table-date-panel
            v-bind="date"
            :read-only-flg="readOnlyFlg"
            :reservation-purpose="reservationPurpose"
            :open-times="openTimes"
            @input="inputReservationAvailablePatternId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarTableHeader from '@/components/parts/molecules/CalendarTableHeader.vue'
import CalendarTableDatePanel from '@/components/parts/molecules/CalendarTableDatePanel.vue'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { timeFormatter } from '@/utils/convert_time'
export default {
  name: 'CalendarTable',
  components: {
    CalendarTableHeader,
    CalendarTableDatePanel
  },

  props: {
    readOnlyFlg: { type: Boolean, default: false },
    reservationPurpose: {
      type: Object,
      default: function() {
        return {}
      }
    },
    openTimes: {
      type: Array,
      required: true
    },
    specialReservationAvailablePatterns: {
      type: Array,
      default: function() {
        return []
      }
    },
    specialOpenTimes: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  data() {
    return {
      currentYear: 0,
      currentMonth: 0,
      weeks: ['日', '月', '火', '水', '木', '金', '土']
    }
  },

  computed: {
    ...mapGetters({
      publicHolidays: 'master/getPublicHolidays',
      reservationAvailablePatterns: 'reservationAvailablePatterns/getData'
    }),
    calendarMake() {
      const necessarySpace = new Date(
        this.currentYear,
        this.currentMonth - 1,
        1
      ).getDay()
      const lastDayWeek = new Date(
        this.currentYear,
        this.currentMonth,
        0
      ).getDay()
      const lastNecessarySpace = 6 - lastDayWeek
      const listArrays = [
        [...Array(necessarySpace)].map(i => {
          return { date: '', hiddenFlg: true }
        }),
        this.dayArray,
        [...Array(lastNecessarySpace)].map(i => {
          return { date: '', hiddenFlg: true }
        })
      ]
      const list = listArrays.reduce((pre, current) => {
        pre.push(...current)
        return pre
      }, [])
      return list
    },
    dayArray() {
      // 日付を入れる配列の作成
      const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
      const array = [...Array(lastDay)]
      return array.map((value, i) => {
        const date = new Date(this.currentYear, this.currentMonth - 1, i + 1)
        const calendarPanelDate = moment(date).format('YYYYMMDD')
        if (calendarPanelDate < moment().format('YYYYMMDD')) {
          return {
            beforeTodayFlg: true,
            date: calendarPanelDate
          }
        }
        const dayOfWeek = date.getDay()
        const selectDataList = this.convertName(
          _.cloneDeep(this.reservationAvailablePatterns),
          this.makeBasicMedicalCareTime(calendarPanelDate, dayOfWeek)
        )
        //reservationPurposeがある場合は来院目的詳細画面用、ない場合は診療日設定画面用で日付データ作成
        const dateData =
          Object.keys(this.reservationPurpose).length != 0
            ? this.makeReservationPurposeDate(
                selectDataList,
                calendarPanelDate,
                dayOfWeek
              )
            : this.makeOpenTimeDate(
                selectDataList,
                calendarPanelDate,
                dayOfWeek
              )
        if (this.publicHolidays.indexOf(calendarPanelDate) != -1)
          dateData.publicHolidaysColorFlg = true
        if (dateData.specialDateFlg) dateData.exceptionDayColorFlg = true
        return dateData
      })
    }
  },

  created() {
    const date = new Date()
    this.currentYear = date.getFullYear()
    this.currentMonth = date.getMonth() + 1
  },

  methods: {
    inputReservationAvailablePatternId(obj) {
      this.$emit('input', obj)
    },
    moveMonth(date) {
      this.currentYear = date.currentYear
      this.currentMonth = date.currentMonth
    },
    makeReservationPurposeDate(selectDataList, calendarPanelDate, dayOfWeek) {
      let selectValue =
        this.publicHolidays.indexOf(calendarPanelDate) != -1
          ? this.reservationPurpose.reservationAvailablePatternsIds[7]
          : this.reservationPurpose.reservationAvailablePatternsIds[dayOfWeek]
      const specialData = this.specialReservationAvailablePatterns.find(
        v => v.date === calendarPanelDate
      )
      if (specialData) {
        selectValue = specialData.reservationAvailablePatternId
        const maxId = Math.max.apply(
          null,
          selectDataList.map(o => o.id)
        )
        selectDataList.push({
          id: maxId ? maxId + 1 : 1,
          name: '選択解除'
        })
      }
      return {
        date: calendarPanelDate,
        selectDataList: selectDataList,
        selectValue: selectValue,
        selectValueName: selectDataList.find(v => v.id === selectValue)
          ? selectDataList.find(v => v.id === selectValue).name
          : '',
        specialDateFlg: specialData ? true : false
      }
    },
    makeOpenTimeDate(selectDataList, calendarPanelDate, dayOfWeek) {
      // opneTimesのtypeカラム→'0:base,1:sunday,2:monday,3:tuesday,4:wednesday,5:thursday,6:friday,7:saturday,8:holiday'
      const openTime =
        this.publicHolidays.indexOf(calendarPanelDate) != -1
          ? this.openTimes.find(v => v.type === 8)
          : this.openTimes.find(v => v.type === dayOfWeek + 1)
      let selectValue =
        openTime.closeFlg === 1
          ? selectDataList.find(v => v.closedDayFlg === 1).id
          : selectDataList.find(v => v.baseTimeFlg === 1).id
      const specialData = this.specialOpenTimes.find(
        v => v.date === calendarPanelDate
      )
      if (specialData) {
        selectValue = specialData.reservationAvailablePatternId
        const maxId = Math.max.apply(
          null,
          selectDataList.map(o => o.id)
        )
        selectDataList.push({
          id: maxId ? maxId + 1 : 1,
          name: '選択解除'
        })
      }
      return {
        date: calendarPanelDate,
        selectDataList: selectDataList,
        selectValue: selectValue,
        selectValueName: selectDataList.find(v => v.id === selectValue)
          ? selectDataList.find(v => v.id === selectValue).name
          : '',
        specialDateFlg: specialData ? true : false
      }
    },
    makeBasicMedicalCareTime(calendarPanelDate, dayOfWeek) {
      // opneTimesのtypeカラム→'0:base,1:sunday,2:monday,3:tuesday,4:wednesday,5:thursday,6:friday,7:saturday,8:holiday'
      const openTime =
        this.publicHolidays.indexOf(calendarPanelDate) != -1
          ? this.openTimes.find(v => v.type === 8)
          : this.openTimes.find(v => v.type === dayOfWeek + 1)
      //reservationPurposeがある場合は来院目的詳細画面用、休診日は、基本診療時間（休診日）で表示する
      //reservationPurposeがない場合は診療日設定画面用、休診日でも、基本診療時間（00:00~00:00, 00:00~00:00）で表示する
      if (
        openTime.closeFlg === 1 &&
        Object.keys(this.reservationPurpose).length != 0
      ) {
        return '休診日'
      } else if (openTime.lunchOpenFlg === 1) {
        return timeFormatter(openTime.start + openTime.end)
      } else {
        return timeFormatter([
          openTime.start + openTime.lunchStart,
          openTime.lunchEnd + openTime.end
        ])
      }
    },
    convertName(reservationAvailablePatterns, basicMedicalCareTime) {
      return reservationAvailablePatterns.map(v => {
        if (v.closedDayFlg === 1) {
          v.name = v.name + '(' + '予約時間なし' + ')'
          return v
        } else if (v.baseTimeFlg === 1) {
          v.name = v.name + '(' + basicMedicalCareTime + ')'
          return v
        } else {
          const times = timeFormatter(v.times)
          v.name = v.name + '(' + times + ')'
          return v
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-table {
  margin: 0 auto;
  > .calendar-body {
    .calendar-body-area-week {
      display: flex;
      margin-bottom: 12.5px;
      .calendar-body-area-day-week {
        width: 14.28%;
        height: 37px;
        > .calendar-body-area-day-week-text {
          width: 94%;
          height: 37px;
          margin: 0 auto;
          border: solid 1px #{$light-grey};
          box-sizing: border-box;
          line-height: 35px;
          text-align: center;
        }
      }
    }
    > .calendar-body-area-days {
      display: flex;
      flex-wrap: wrap;
      > .calendar-body-area-day {
        width: 14.28%;
      }
    }
  }
}
</style>
