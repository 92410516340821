<template>
  <div class="reservation-setting-period-form-date">
    <div class="content-form">
      <base-date-picker
        :value="startDate"
        :disabledDates="dates"
        :disabled="dateSpecifyFormDisabled"
        @input="inputStartDate"
        data-test="start-date"
      />
      <div class="text-area">から</div>
      <base-date-picker
        :value="endDate"
        :disabledDates="dates"
        :disabled="dateSpecifyFormDisabled"
        :minDate="startDate"
        @input="inputEndDate"
        data-test="end-date"
      />
      <div class="text-area">まで予約可能</div>
    </div>
    <div class="error" v-if="invalidPeriodFlg" data-test="error">
      終了日は開始日より後の日付にしてください
    </div>
  </div>
</template>

<script>
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import moment from 'moment'

export default {
  name: 'ReservationSettingPeriodFormDate',

  components: { BaseDatePicker },

  props: {
    reservationAvailablePeriodType: {
      type: Number
    },
    reservationStartDate: {
      type: String
    },
    reservationEndDate: {
      type: String
    },
    invalidPeriodFlg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dates: {
        years: [...Array(2020).keys()]
      }
    }
  },

  computed: {
    dateSpecifyFormDisabled() {
      return this.reservationAvailablePeriodType !== 2 ? true : false
    },
    startDate() {
      return this.reservationStartDate === ''
        ? moment().format('YYYYMMDD')
        : this.reservationStartDate
    },
    endDate() {
      return this.reservationEndDate === ''
        ? moment().format('YYYYMMDD')
        : this.reservationEndDate
    }
  },

  methods: {
    inputStartDate(val) {
      this.$emit('input', val, 'reservationStartDate')
    },
    inputEndDate(val) {
      this.$emit('input', val, 'reservationEndDate')
    }
  }
}
</script>

<style lang="scss" scpoed>
.reservation-setting-period-form-date {
  position: relative;
  margin-bottom: 30px;
  > .content-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 10px;
    > .text-area {
      line-height: 33px;
      margin: 0px 10px;
    }
  }
  > .error {
    position: absolute;
    margin-left: 220px;
    color: #{$tomato};
    font-size: 12px;
  }
}
</style>
