<template>
  <div class="reservation-purpose-new">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">来院目的登録</div>
      <div class="setting-content">
        <div class="basic-setting-title">基本設定</div>
        <reservation-purpose-input-form
          :reservation-purpose="reservationPurpose"
          :register-reservation-columns="registerReservationColumns"
          :state-reservation-columns="stateReservationColumns"
          :register-staffs="registerStaffs"
          :state-staffs="stateStaffs"
          @input="inputReservationPurpose"
          @click="setReservationColumnsAndStaffs"
        />
        <reservation-purpose-custom-area
          v-if="reservationPurpose.customFlg"
          :new-and-edit-flg="true"
          :reservation-purpose="reservationPurpose"
          :special-reservation-available-patterns="
            specialReservationAvailablePatterns
          "
          @input-reservation-available-pattern-id="
            inputReservationAvailablePatternId
          "
          @input-special-reservation-available-pattern="
            inputSpecialReservationAvailablePattern
          "
          @open-patterns-page="openPatternsPage"
        />
        <div class="button-area">
          <base-button-medium-white class="button" @click="backListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || !allValidFlg || waitFlg"
            @click="checkReservationColumnStaff"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        :leftAlignMessage="leftAlignMessage"
        @close="openListPage"
        @cancel="cancelDecision"
        @decision="decision"
        ><div v-html="popupMessage"></div
      ></announce-popup>
    </validation-observer>
  </div>
</template>

<script>
import ReservationPurposeInputForm from '@/components/parts/organisms/ReservationPurposeInputForm.vue'
import ReservationPurposeCustomArea from '@/components/parts/organisms/ReservationPurposeCustomArea.vue'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'

export default {
  name: 'ReservationPurposeNew',

  components: {
    ReservationPurposeInputForm,
    ReservationPurposeCustomArea,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      reservationPurpose: {},
      stateReservationColumns: [],
      registerReservationColumns: [],
      stateStaffs: [],
      registerStaffs: [],
      specialReservationAvailablePatterns: [],
      alertFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '新規登録しました',
      leftAlignMessage: '',
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      staffReservationColumns: 'staffReservationColumns/getData',
      getColumn: 'reservationColumns/getDataById',
      reservationColumns: 'reservationColumns/getData',
      getStaff: 'staffs/getDataById',
      staffs: 'staffs/getStaffs',
      reservationAvailablePatterns: 'reservationAvailablePatterns/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    reservationColumnsValidFlg() {
      return this.registerReservationColumns.length >= 1
    },
    staffsValidFlg() {
      return this.registerStaffs.length >= 1
    },
    allValidFlg() {
      const valid = this.reservationColumnsValidFlg && this.staffsValidFlg
      return valid
    }
  },

  watch: {
    'reservationPurpose.time': function(time) {
      if (time.HH === '00' && time.mm === '00') {
        this.$set(this.reservationPurpose, 'time', { HH: '00', mm: '05' })
      } else if (time.HH === '03' && time.mm !== '00') {
        this.$set(this.reservationPurpose, 'time', { HH: '03', mm: '00' })
      }
    }
  },

  created() {
    const basicTimeDataId = this.reservationAvailablePatterns.find(
      v => v.baseTimeFlg === 1
    ).id
    this.reservationPurpose = {
      name: '',
      time: { HH: '00', mm: '05' },
      reservationColumnsIds: [],
      staffsIds: [],
      firstShowFlg: 1,
      secondShowFlg: 1,
      maxPatients: 1,
      customFlg: 0,
      reservationAvailablePatternsIds: [...Array(8)].map(i => basicTimeDataId)
    }
    this.stateReservationColumns = this.staffReservationColumns.map(v => {
      const reservationColumn = this.getColumn(v.reservationColumnId)
      const staff =
        reservationColumn.staffId === 0
          ? null
          : this.getStaff(reservationColumn.staffId)
      const staffName =
        staff !== null ? '(' + staff.lastName + staff.firstName + ')' : ''
      return {
        ...reservationColumn,
        name: reservationColumn.name + staffName,
        order: v.order
      }
    })
    this.stateStaffs = this.staffs.map(v => {
      return { ...v, name: v.lastName + v.firstName }
    })
    this.mixinInputData = {
      reservationPurpose: this.reservationPurpose,
      specialReservationAvailablePatterns: this
        .specialReservationAvailablePatterns
    }
    this.mixinInitialData = _.cloneDeep(this.mixinInputData)
  },

  methods: {
    inputReservationPurpose(val, property) {
      if (property === 'maxPatients') {
        this.reservationPurpose[property] =
          parseInt(val, 10).toString() === val ? parseInt(val) : ''
      } else {
        this.reservationPurpose[property] = val
      }
    },
    setReservationColumnsAndStaffs(
      registerData,
      stateData,
      property,
      selectData,
      event
    ) {
      if (property === 'reservationColumns') {
        this.registerReservationColumns = registerData
        this.stateReservationColumns = stateData
        this.reservationPurpose.reservationColumnsIds = registerData.map(
          v => v.id
        )
        if (selectData && selectData.staffId !== 0 && event === 'add') {
          const staff = this.stateStaffs.find(s => s.id === selectData.staffId)
          if (staff) {
            this.registerStaffs.push(staff)
            this.stateStaffs = this.stateStaffs.filter(s => s !== staff)
          }
        }
      } else {
        this.registerStaffs = registerData
        this.stateStaffs = stateData
        this.reservationPurpose.staffsIds = registerData.map(v => v.id)
        if (selectData && event === 'cancel') {
          const reservationColumn = this.registerReservationColumns.find(
            r => r.staffId === selectData.id
          )
          if (reservationColumn) {
            this.stateReservationColumns.push(reservationColumn)
            this.stateReservationColumns.sort((a, b) => {
              return a.order - b.order
            })
            this.registerReservationColumns = this.registerReservationColumns.filter(
              r => r !== reservationColumn
            )
          }
        }
      }
    },
    inputReservationAvailablePatternId(obj) {
      this.reservationPurpose.reservationAvailablePatternsIds.splice(
        obj.targetIndex,
        1,
        obj.selectId
      )
    },
    inputSpecialReservationAvailablePattern(obj) {
      const targetIndex = this.specialReservationAvailablePatterns.findIndex(
        v => v.date === obj.date
      )
      if (obj.deleteFlg || obj.name === '選択解除') {
        this.specialReservationAvailablePatterns.splice(targetIndex, 1)
      } else if (targetIndex != -1) {
        this.$set(
          this.specialReservationAvailablePatterns[targetIndex],
          'reservationAvailablePatternId',
          obj.value
        )
      } else {
        this.specialReservationAvailablePatterns.push({
          date: obj.date,
          reservationAvailablePatternId: obj.value
        })
      }
    },
    checkReservationColumnStaff() {
      if (this.registerReservationColumns.every(r => r.staffId !== 0)) {
        const ngStaffs = this.registerStaffs.filter(
          s =>
            !this.registerReservationColumns.map(r => r.staffId).includes(s.id)
        )
        if (ngStaffs.length > 0) {
          this.alertFlg = true
          this.title = '無効なスタッフ'
          this.type = 'alert'
          this.buttons = ['キャンセル', '登録']
          const ngStaffNames = ngStaffs
            .map(s => s.lastName + s.firstName)
            .join(',')
          this.popupMessage =
            '全ての予約列にスタッフが設定されているため、\n' +
            ngStaffNames +
            ' は有効ではありません。\n有効なスタッフだけで登録しますか？'
        } else {
          this.createData()
        }
      } else {
        this.createData()
      }
    },
    cancelDecision() {
      this.alertFlg = false
      this.buttons = ['閉じる']
    },
    decision() {
      this.alertFlg = false
      this.buttons = ['閉じる']
      this.registerStaffs = this.registerStaffs.filter(s =>
        this.registerReservationColumns.map(r => r.staffId).includes(s.id)
      )
      this.createData()
    },
    async createData() {
      this.waitFlg = true
      const reservationPurposeData = _.cloneDeep(this.reservationPurpose)
      reservationPurposeData.time =
        parseInt(this.reservationPurpose.time.HH) * 60 +
        parseInt(this.reservationPurpose.time.mm)
      reservationPurposeData.reservationColumnsIds = this.registerReservationColumns
        .map(v => v.id)
        .join(',')
      reservationPurposeData.staffsIds = this.registerStaffs
        .map(v => v.id)
        .join(',')
      reservationPurposeData.reservationAvailablePatternsIds = reservationPurposeData.reservationAvailablePatternsIds.join(
        ','
      )
      let specialReservationAvailablePatternsData = this
        .specialReservationAvailablePatterns
      if (reservationPurposeData.customFlg === 0) {
        const basicTimeDataId = this.reservationAvailablePatterns.find(
          v => v.baseTimeFlg === 1
        ).id
        reservationPurposeData.reservationAvailablePatternsIds = [...Array(8)]
          .map(i => basicTimeDataId)
          .join(',')
        specialReservationAvailablePatternsData = []
      }
      const obj = {
        reservationPurpose: reservationPurposeData,
        specialReservationAvailablePatterns: specialReservationAvailablePatternsData
      }
      const res = await this.$store.dispatch('reservationPurposes/create', obj)
      if (res.status === 200) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '新規登録しました'
        this.leftAlignMessage = res.leftAlignMessage
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res.status === 404) {
          this.popupMessage =
            res.extra === 'reservationColumn'
              ? '登録に失敗しました。\n選択した予約列の中で、既に削除された予約列が使用されています。'
              : res.extra === 'staff'
              ? '登録に失敗しました。\n選択したスタッフの中で、既に削除または退職されたスタッフが使用されています。'
              : '登録に失敗しました。\n選択した受付時間パターンの中で、既に削除された受付時間パターンが使用されています。'
          this.resetData()
          this.popupMessage +=
            '\n最新データに更新しましたので再編集をお願いします。'
        } else {
          this.popupMessage = '登録に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    async resetData() {
      const columnsIds = this.reservationColumns.map(obj => obj.id)
      this.registerReservationColumns = this.registerReservationColumns.filter(
        v => columnsIds.includes(v.id)
      )
      this.stateReservationColumns = this.stateReservationColumns.filter(v =>
        columnsIds.includes(v.id)
      )
      const staffIds = this.staffs.map(obj => obj.id)
      this.registerStaffs = this.registerStaffs.filter(v =>
        staffIds.includes(v.id)
      )
      this.stateStaffs = this.stateStaffs.filter(v => staffIds.includes(v.id))
      const patternsIds = this.reservationAvailablePatterns.map(obj => obj.id)
      this.reservationPurpose.reservationAvailablePatternsIds = this.reservationPurpose.reservationAvailablePatternsIds.map(
        id =>
          patternsIds.includes(id)
            ? id
            : this.reservationAvailablePatterns.find(v => v.baseTimeFlg === 1)
                .id
      )
      this.specialReservationAvailablePatterns = this.specialReservationAvailablePatterns.filter(
        v => patternsIds.includes(v.reservationAvailablePatternId)
      )
    },
    openListPage() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'reservation-purposes' })
      }
    },
    backListPage() {
      this.$router.push({ name: 'reservation-purposes' })
    },
    openPatternsPage() {
      this.$router.push({ name: 'reservation-available-patterns' })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-new {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      font-size: 15px;
      > .basic-setting-title {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #{$light-grey};
        font-weight: bold;
      }
      > .button-area {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
