<template>
  <div class="reservation-available-pattern-input-form">
    <text-input-form
      :requiredFlg="true"
      :maxlength="20"
      :placeholder="'20文字まで'"
      :value="reservationAvailablePattern.name"
      @input="inputName"
      >パターン名</text-input-form
    >
    <div class="time-input-form">
      <div class="heading">設定時間<span class="require">必須</span></div>
      <div class="body">
        <div
          class="time-item"
          v-for="(obj, index) in reservationAvailablePattern.times"
          :key="obj.key"
        >
          <div class="time-item-area">
            <div class="time-text">時間{{ index + 1 }}</div>
            <div class="time-start">
              <base-time-input-field
                :value="obj.startTime"
                input-width="90px"
                @input="inputStartTime($event, index)"
                :minute-interval="5"
                :hour-label="'時'"
                :minute-label="'分'"
              />
            </div>
            <div class="time-text">～</div>
            <div class="time-end">
              <base-time-input-field
                :value="obj.endTime"
                input-width="90px"
                @input="inputEndTime($event, index)"
                :minute-interval="5"
                :hour-label="'時'"
                :minute-label="'分'"
              />
            </div>
            <base-button-trash
              v-if="reservationAvailablePattern.times.length != 1"
              @click="delPattern(index)"
            />
          </div>
          <div v-if="!periodValidFlg(obj)" class="error-message-period">
            {{ '終了時間は開始時間より後に設定してください' }}
          </div>
        </div>
        <div v-if="!patternValidFlg" class="error-message-pattern">
          {{ '若番の時間より前または重複しないように設定してください' }}
        </div>
      </div>
    </div>
    <div class="add-button">
      <base-button-plus @click="addPattern" :text="'時間追加'" />
    </div>
  </div>
</template>

<script>
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import BaseTimeInputField from '@/components/parts/atoms/BaseTimeInputField'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import { validTimePatterns } from '@/utils/reservation_available_pattern'

export default {
  name: 'ReservationAvailablePatternInputForm',

  components: {
    TextInputForm,
    BaseTimeInputField,
    BaseButtonPlus,
    BaseButtonTrash
  },

  props: {
    reservationAvailablePattern: {
      name: { type: String },
      times: {
        type: Array,
        validator: function(arr) {
          return arr.every(v => {
            return (
              Number.isInteger(v.key) &&
              typeof v.startTime === 'string' &&
              typeof v.endTime === 'string'
            )
          })
        }
      }
    }
  },

  computed: {
    periodValidFlg: function() {
      return function(reservationAvailablePattern) {
        return (
          this.toTime(reservationAvailablePattern.startTime) <
          this.toTime(reservationAvailablePattern.endTime)
        )
      }
    },
    patternValidFlg() {
      return validTimePatterns(this.convertTimes)
    },
    convertTimes() {
      return this.reservationAvailablePattern.times.map(v => {
        return {
          startTime: this.toTime(v.startTime),
          endTime: this.toTime(v.endTime)
        }
      })
    }
  },

  methods: {
    toTime(colonTime) {
      return ('0000' + colonTime.split(':').join('')).slice(-4)
    },
    inputName(val) {
      this.$emit('input-name', val)
    },
    inputStartTime(val, index) {
      this.$emit('input-start-time', {
        val: val,
        targetIndex: index
      })
    },
    inputEndTime(val, index) {
      this.$emit('input-end-time', {
        val: val,
        targetIndex: index
      })
    },
    addPattern() {
      this.$emit('add-pattern')
    },
    delPattern(targetIndex) {
      this.$emit('del-pattern', targetIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-available-pattern-input-form {
  width: 600px;
  > .time-input-form {
    margin-top: 19px;
    display: flex;
    font-size: 15px;
    > .heading {
      line-height: 33px;
      width: 40%;
      > .require {
        padding-left: 10px;
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > .body {
      width: 60%;
      font-size: 13px;
      position: relative;
      > .time-item {
        margin-bottom: 25px;
        position: relative;
        > .time-item-area {
          display: flex;
          > .time-text {
            line-height: 33px;
          }
          > .time-start {
            margin: 0px 10px;
          }
          > .time-end {
            margin: 0px 20px 0px 10px;
          }
        }
        > .error-message-period {
          position: absolute;
          z-index: 1;
          color: #{$tomato};
          font-size: 12px;
        }
      }
      > .error-message-pattern {
        position: absolute;
        z-index: 1;
        color: #{$tomato};
        font-size: 12px;
      }
    }
  }
  > .add-button {
    margin-top: 20px;
    margin-left: 240px;
  }
}
</style>
