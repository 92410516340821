const propertyRemoveFilterMaker = properties => {
  const only = obj => {
    let returnObj = {}
    const keys = Object.keys(obj)
    for (let key of keys) {
      if (!properties.includes(key)) {
        returnObj[key] = obj[key]
      }
    }
    return returnObj
  }
  const propertyRemoveFilter = obj => {
    if (obj instanceof Array) {
      return obj.map(only)
    } else {
      return only(obj)
    }
  }
  return propertyRemoveFilter
}

const removeClinicIdCreateUpdate = propertyRemoveFilterMaker([
  'clinicId',
  'createdAt',
  'updatedAt'
])

const removeClinicIdDelCreateUpdate = propertyRemoveFilterMaker([
  'clinicId',
  'delFlg',
  'createdAt',
  'updatedAt'
])

const removeKeyItemNameTotalPriceUnitTypeId = propertyRemoveFilterMaker([
  'key',
  'itemName',
  'totalPrice',
  'unitTypeId'
])

module.exports = {
  propertyRemoveFilterMaker,
  removeClinicIdCreateUpdate,
  removeClinicIdDelCreateUpdate,
  removeKeyItemNameTotalPriceUnitTypeId
}
