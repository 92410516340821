<template>
  <div class="base-button-tiny-white">
    <button type="button" :disabled="disabled" @click="handleClick">
      <span><slot /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonTinyWhite',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-tiny-white {
  > button {
    width: 33px;
    height: 33px;
    border-radius: 6px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$white_f7};
      color: #{$light-grey};
      border: none;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
    > span {
      height: 19px;
      font-size: 13px;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
