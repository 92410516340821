<template>
  <div class="reservation-purpose-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">来院目的編集</div>
      <div class="setting-content">
        <div class="basic-setting-title">基本設定</div>
        <reservation-purpose-input-form
          :reservation-purpose="reservationPurpose"
          :register-reservation-columns="registerReservationColumns"
          :state-reservation-columns="stateReservationColumns"
          :register-staffs="registerStaffs"
          :state-staffs="stateStaffs"
          @input="inputReservationPurpose"
          @click="setReservationColumnsAndStaffs"
        />
        <reservation-purpose-custom-area
          v-if="reservationPurpose.customFlg"
          :new-and-edit-flg="true"
          :reservation-purpose="reservationPurpose"
          :special-reservation-available-patterns="
            specialReservationAvailablePatternsData
          "
          @input-reservation-available-pattern-id="
            inputReservationAvailablePatternId
          "
          @input-special-reservation-available-pattern="
            inputSpecialReservationAvailablePattern
          "
          @open-patterns-page="openPatternsPage"
        />
        <div class="button-area">
          <base-button-medium-white class="button" @click="backShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || !allValidFlg || waitFlg"
            @click="checkReservationColumnStaff"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        :leftAlignMessage="leftAlignMessage"
        @close="openShowPage"
        @cancel="cancelDecision"
        @decision="decision"
        ><div v-html="popupMessage"></div
      ></announce-popup>
    </validation-observer>
  </div>
</template>

<script>
import ReservationPurposeInputForm from '@/components/parts/organisms/ReservationPurposeInputForm.vue'
import ReservationPurposeCustomArea from '@/components/parts/organisms/ReservationPurposeCustomArea.vue'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { removeClinicIdDelCreateUpdate } from '@/utils/property_filter'

export default {
  name: 'ReservationPurposeEdit',

  components: {
    ReservationPurposeInputForm,
    ReservationPurposeCustomArea,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      reservationPurpose: {},
      registerReservationColumns: [],
      stateReservationColumns: [],
      registerStaffs: [],
      stateStaffs: [],
      specialReservationAvailablePatternsData: [],
      waitFlg: false,
      alertFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '編集しました',
      leftAlignMessage: '',
      initialReservationPurpose: {},
      initialSpecialPatterns: {},
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getReservationPurpose: 'reservationPurposes/getDataById',
      staffReservationColumns: 'staffReservationColumns/getData',
      getColumn: 'reservationColumns/getDataById',
      reservationColumns: 'reservationColumns/getData',
      getStaff: 'staffs/getDataById',
      staffs: 'staffs/getStaffs',
      reservationAvailablePatterns: 'reservationAvailablePatterns/getData',
      specialReservationAvailablePatterns:
        'specialReservationAvailablePatterns/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    reservationColumnsValidFlg() {
      return this.registerReservationColumns.length >= 1
    },
    staffsValidFlg() {
      return this.registerStaffs.length >= 1
    },
    allValidFlg() {
      const valid = this.reservationColumnsValidFlg && this.staffsValidFlg
      return valid
    }
  },

  watch: {
    'reservationPurpose.time': function(time) {
      if (time.HH === '00' && time.mm === '00') {
        this.$set(this.reservationPurpose, 'time', { HH: '00', mm: '05' })
      } else if (time.HH === '03' && time.mm !== '00') {
        this.$set(this.reservationPurpose, 'time', { HH: '03', mm: '00' })
      }
    }
  },

  created() {
    this.reservationPurpose = removeClinicIdDelCreateUpdate(
      _.cloneDeep(this.getReservationPurpose(this.id))
    )
    const hour =
      Math.floor(this.reservationPurpose.time / 60) != 0
        ? ('0' + String(Math.floor(this.reservationPurpose.time / 60))).slice(
            -2
          )
        : '00'
    const minute =
      this.reservationPurpose.time % 60 != 0
        ? ('0' + String(this.reservationPurpose.time % 60)).slice(-2)
        : '00'
    this.reservationPurpose.time = { HH: hour, mm: minute }
    const reservationColumns = this.staffReservationColumns.map(v => {
      const reservationColumn = this.getColumn(v.reservationColumnId)
      const staff =
        reservationColumn.staffId === 0
          ? null
          : this.getStaff(reservationColumn.staffId)
      const staffName =
        staff !== null ? '(' + staff.lastName + staff.firstName + ')' : ''
      return {
        ...reservationColumn,
        name: reservationColumn.name + staffName,
        order: v.order
      }
    })
    this.registerReservationColumns = this.reservationPurpose.reservationColumnsIds.map(
      id => reservationColumns.find(obj => obj.id === id)
    )
    this.stateReservationColumns = reservationColumns.filter(
      v => !this.reservationPurpose.reservationColumnsIds.includes(v.id)
    )
    const copyStaffs = this.staffs.map(v => {
      return { ...v, name: v.lastName + v.firstName }
    })
    this.registerStaffs = this.reservationPurpose.staffsIds.map(id =>
      copyStaffs.find(obj => obj.id === id)
    )
    this.stateStaffs = copyStaffs.filter(
      v => !this.reservationPurpose.staffsIds.includes(v.id)
    )
    this.specialReservationAvailablePatternsData = removeClinicIdDelCreateUpdate(
      this.specialReservationAvailablePatterns.filter(
        v => v.reservationPurposeId === this.reservationPurpose.id
      )
    )
    this.initialReservationPurpose = _.cloneDeep(this.reservationPurpose)
    this.initialSpecialPatterns = _.cloneDeep(
      this.specialReservationAvailablePatternsData
    )
    this.mixinInputData = {
      reservationPurpose: this.reservationPurpose,
      specialReservationAvailablePatterns: this
        .specialReservationAvailablePatternsData
    }
    this.mixinSetInitialData()
  },

  methods: {
    inputReservationPurpose(val, property) {
      if (property === 'maxPatients') {
        this.reservationPurpose[property] =
          parseInt(val, 10).toString() === val ? parseInt(val) : ''
      } else {
        this.reservationPurpose[property] = val
      }
    },
    setReservationColumnsAndStaffs(
      registerData,
      stateData,
      property,
      selectData = null,
      event = ''
    ) {
      if (property === 'reservationColumns') {
        this.registerReservationColumns = registerData
        this.stateReservationColumns = stateData
        this.reservationPurpose.reservationColumnsIds = registerData.map(
          v => v.id
        )
        if (selectData && selectData.staffId !== 0 && event === 'add') {
          const staff = this.stateStaffs.find(s => s.id === selectData.staffId)
          if (staff) {
            this.registerStaffs.push(staff)
            this.stateStaffs = this.stateStaffs.filter(s => s !== staff)
          }
        }
      } else {
        this.registerStaffs = registerData
        this.stateStaffs = stateData
        this.reservationPurpose.staffsIds = registerData.map(v => v.id)
        if (selectData && event === 'cancel') {
          const reservationColumn = this.registerReservationColumns.find(
            r => r.staffId === selectData.id
          )
          if (reservationColumn) {
            this.stateReservationColumns.push(reservationColumn)
            this.stateReservationColumns.sort((a, b) => {
              return a.order - b.order
            })
            this.registerReservationColumns = this.registerReservationColumns.filter(
              r => r !== reservationColumn
            )
          }
        }
      }
    },
    inputReservationAvailablePatternId(obj) {
      this.reservationPurpose.reservationAvailablePatternsIds.splice(
        obj.targetIndex,
        1,
        obj.selectId
      )
    },
    inputSpecialReservationAvailablePattern(obj) {
      const targetIndex = this.specialReservationAvailablePatternsData.findIndex(
        v => v.date === obj.date
      )
      if (obj.deleteFlg || obj.name === '選択解除') {
        if (this.specialReservationAvailablePatternsData[targetIndex].id) {
          this.$set(
            this.specialReservationAvailablePatternsData[targetIndex],
            'deleteFlg',
            true
          )
        } else {
          this.specialReservationAvailablePatternsData.splice(targetIndex, 1)
        }
      } else if (targetIndex != -1) {
        if (
          this.specialReservationAvailablePatternsData[targetIndex].deleteFlg
        ) {
          this.$delete(
            this.specialReservationAvailablePatternsData[targetIndex],
            'deleteFlg'
          )
        }
        this.$set(
          this.specialReservationAvailablePatternsData[targetIndex],
          'reservationAvailablePatternId',
          obj.value
        )
      } else {
        this.specialReservationAvailablePatternsData.push({
          date: obj.date,
          reservationAvailablePatternId: obj.value,
          reservationPurposeId: this.reservationPurpose.id
        })
      }
    },
    async updateData() {
      this.waitFlg = true
      const reservationPurposeData = _.cloneDeep(this.reservationPurpose)
      reservationPurposeData.time =
        parseInt(this.reservationPurpose.time.HH) * 60 +
        parseInt(this.reservationPurpose.time.mm)
      reservationPurposeData.reservationColumnsIds = this.registerReservationColumns
        .map(v => v.id)
        .join(',')
      reservationPurposeData.staffsIds = this.registerStaffs
        .map(v => v.id)
        .join(',')
      reservationPurposeData.reservationAvailablePatternsIds = reservationPurposeData.reservationAvailablePatternsIds.join(
        ','
      )
      let specialReservationAvailablePatternsData = this
        .specialReservationAvailablePatternsData
      if (reservationPurposeData.customFlg === 0) {
        reservationPurposeData.reservationAvailablePatternsIds = this.getReservationPurpose(
          this.id
        ).reservationAvailablePatternsIds.join(',')
        specialReservationAvailablePatternsData = []
      }
      const obj = {
        reservationPurpose: reservationPurposeData,
        specialReservationAvailablePatterns: specialReservationAvailablePatternsData
      }
      const res = await this.$store.dispatch('reservationPurposes/update', obj)
      if (res.status === 200) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.leftAlignMessage = res.leftAlignMessage
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (res.status === 404) {
          if (
            res.extra === 'reservationPurpose' ||
            res.extra.hasOwnProperty('data')
          ) {
            this.popupMessage =
              '編集に失敗しました。\n編集中の来院目的は既に削除されています。\n来院目的一覧画面へ移動します。'
            this.noDataFlg = true
          } else {
            this.popupMessage =
              res.extra === 'reservationColumn'
                ? '編集に失敗しました。\n選択した予約列の中で、既に削除された予約列が使用されています。'
                : res.extra === 'staff'
                ? '編集に失敗しました。\n選択したスタッフの中で、既に削除または退職されたスタッフが使用されています。'
                : '編集に失敗しました。\n選択した受付時間パターンの中で、既に削除された受付時間パターンが使用されています。'
            await this.resetData()
            this.popupMessage +=
              '\n最新データに更新しましたので再編集をお願いします。'
          }
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    async resetData() {
      const columnsIds = this.reservationColumns.map(obj => obj.id)
      this.registerReservationColumns = this.registerReservationColumns.filter(
        v => columnsIds.includes(v.id)
      )
      this.stateReservationColumns = this.stateReservationColumns.filter(v =>
        columnsIds.includes(v.id)
      )
      const staffIds = this.staffs.map(obj => obj.id)
      this.registerStaffs = this.registerStaffs.filter(v =>
        staffIds.includes(v.id)
      )
      this.stateStaffs = this.stateStaffs.filter(v => staffIds.includes(v.id))
      const patternsIds = this.reservationAvailablePatterns.map(obj => obj.id)
      this.reservationPurpose.reservationAvailablePatternsIds = this.reservationPurpose.reservationAvailablePatternsIds.map(
        (id, i) =>
          patternsIds.includes(id)
            ? id
            : this.initialReservationPurpose.reservationAvailablePatternsIds[i]
      )
      this.specialReservationAvailablePatternsData = this.specialReservationAvailablePatternsData
        .filter(
          v =>
            patternsIds.includes(v.reservationAvailablePatternId) ||
            this.initialSpecialPatterns.map(obj => obj.id).includes(v.id)
        )
        .map(v => {
          const targetIndex = this.initialSpecialPatterns.findIndex(
            obj =>
              obj.id === v.id &&
              !patternsIds.includes(v.reservationAvailablePatternId)
          )
          return targetIndex != -1
            ? this.initialSpecialPatterns[targetIndex]
            : v
        })
    },
    checkReservationColumnStaff() {
      if (this.registerReservationColumns.every(r => r.staffId !== 0)) {
        const ngStaffs = this.registerStaffs.filter(
          s =>
            !this.registerReservationColumns.map(r => r.staffId).includes(s.id)
        )
        if (ngStaffs.length > 0) {
          this.alertFlg = true
          this.title = '無効なスタッフ'
          this.type = 'alert'
          this.buttons = ['キャンセル', '登録']
          const ngStaffNames = ngStaffs
            .map(s => s.lastName + s.firstName)
            .join(',')
          this.popupMessage =
            '全ての予約列にスタッフが設定されているため、\n' +
            ngStaffNames +
            ' は有効ではありません。\n有効なスタッフだけで登録しますか？'
        } else {
          this.updateData()
        }
      } else {
        this.updateData()
      }
    },
    cancelDecision() {
      this.alertFlg = false
      this.buttons = ['閉じる']
    },
    decision() {
      this.alertFlg = false
      this.buttons = ['閉じる']
      this.registerStaffs = this.registerStaffs.filter(s =>
        this.registerReservationColumns.map(r => r.staffId).includes(s.id)
      )
      this.registerReservationColumns.forEach(column => {
        if (!this.registerStaffs.some(s => s.id === column.staffId)) {
          const staff = this.getStaff(column.staffId)
          if (staff) {
            this.registerStaffs.push({
              ...staff,
              name: staff.lastName + ' ' + staff.firstName
            })
            this.stateStaffs = this.stateStaffs.filter(s => s.id !== staff.id)
          }
        }
      })
      this.updateData()
    },
    openShowPage() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.backShowPage()
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'reservation-purposes' })
      }
    },
    backShowPage() {
      this.$router.push({
        name: 'reservation-purpose-show',
        params: { id: this.id }
      })
    },
    openPatternsPage() {
      this.$router.push({ name: 'reservation-available-patterns' })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-edit {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      font-size: 15px;
      > .basic-setting-title {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #{$light-grey};
        font-weight: bold;
      }
      > .button-area {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
