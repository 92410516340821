<template>
  <div class="reservation-purposes">
    <div class="content">
      <div class="button-wrap" v-if="!mixinSortModeFlg">
        <base-button-register
          class="button"
          v-if="lookOnlyFlg === 0"
          :text="'来院目的登録'"
          @click="openNewPage"
        />
        <base-sort-button
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="setSortMode"
        />
      </div>
      <div class="button-wrap" v-else>
        <base-button-small-white class="button" @click="cancelSortMode"
          >キャンセル</base-button-small-white
        >
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="waitFlg"
          @click="updateOrder"
          >並び順登録</base-button-small-orange
        >
      </div>
      <div class="search-wrap">
        <div class="heading">来院目的検索</div>
        <search-area
          :textBoxLabel="'検索単語'"
          :textValue="searchWord"
          :placeholder="'来院目的名'"
          :disabled="mixinSortModeFlg"
          v-model="searchWord"
        />
      </div>
      <div class="list-wrap">
        <div class="heading">
          来院目的一覧<span v-if="mixinSortModeFlg">{{
            mixinSortModeMessage
          }}</span>
        </div>
        <list-table
          :selectBoxFlg="true"
          :headerData="headers"
          :propertyData="properties"
          :bodyData="displayReservationPurposes"
          :sortModeFlg="mixinSortModeFlg"
          @click="openShowPage"
          @order="changeOrder"
        />
      </div>
    </div>
    <announce-popup
      v-if="mixinSortPopup.showFlg"
      :type="mixinSortPopup.type"
      :title="mixinSortPopup.title"
      :buttons="mixinSortPopup.buttons"
      @close="mixinCloseSortPopup"
      >{{ mixinSortPopup.message }}</announce-popup
    >
    <unsaved-leave-popup />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import SearchArea from '@/components/parts/molecules/SearchArea'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import ApiOrder from '@/components/mixins/ApiOrder'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationPurposes',

  components: {
    BaseButtonRegister,
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    SearchArea,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ApiOrder, CheckInputDifference],

  data() {
    return {
      headers: [
        '標準',
        '来院目的名',
        '予約列',
        'スタッフ',
        '時間',
        '未登録飼主表示',
        '登録済飼主表示',
        'カスタム設定'
      ],
      properties: [
        'order',
        'name',
        'reservationColumnsNames',
        'staffsNames',
        'time',
        'firstShow',
        'secondShow',
        'customStatus'
      ],
      searchWord: '',
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      reservationPurposes: 'reservationPurposes/getData',
      getReservationColumn: 'reservationColumns/getDataById',
      getStaff: 'staffs/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayReservationPurposes() {
      const data = this.mixinSortModeFlg
        ? this.mixinInputData
        : this.reservationPurposes
      return data
        .map(row => {
          const reservationColumnsNames = row.reservationColumnsIds
            .map(columnId => this.getReservationColumn(columnId).name)
            .join(',')
          const staffsNames = row.staffsIds
            .map(staffId => {
              const staff = this.getStaff(staffId)
              return staff.lastName + staff.firstName
            })
            .join(',')
          return {
            ...row,
            reservationColumnsNames,
            staffsNames,
            firstShow: row.firstShowFlg === 1 ? '表示' : '非表示',
            secondShow: row.secondShowFlg === 1 ? '表示' : '非表示',
            customStatus: row.customFlg === 1 ? '有効' : '無効'
          }
        })
        .filter(v =>
          v.name.toLowerCase().includes(this.searchWord.toLowerCase().trim())
        )
    }
  },

  watch: {
    mixinSortModeFlg: function() {
      this.setData()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = this.reservationPurposes
      this.mixinSetInitialData()
    },
    openNewPage() {
      this.$router.push({ name: 'reservation-purpose-new' })
    },
    openShowPage(id) {
      this.$router.push({ name: 'reservation-purpose-show', params: { id } })
    },
    setSortMode() {
      this.mixinSortModeFlg = true
      this.searchWord = ''
    },
    cancelSortMode() {
      this.mixinSortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData = data.map(v1 =>
        this.mixinInputData.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      await this.mixinUpdateOrder('reservationPurposes', this.mixinInputData)
      if (this.mixinSortSucceeded) this.mixinSetInitialData()
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purposes {
  > .content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .button-wrap {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .search-wrap {
      margin-top: 16px;
    }
    > .list-wrap {
      min-width: 840px;
      margin-top: 26px;
      ::v-deep .list-table .table-body {
        max-height: 560px;
      }
    }
  }
}
.heading {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: bold;
}
</style>
