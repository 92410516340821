<template>
  <div class="reservation-purpose-input-form">
    <div class="common-form">
      <text-input-form
        :requiredFlg="true"
        :maxlength="30"
        :value="reservationPurpose.name"
        @input="inputName"
        >来院目的名</text-input-form
      >
    </div>
    <div class="common-form" v-if="enAvailable">
      <text-input-form
        :requiredFlg="true"
        :maxlength="60"
        :value="reservationPurpose.nameEnglish"
        @input="inputNameEnglish"
        >来院目的名（英語）</text-input-form
      >
    </div>
    <div class="reservation-purpose-column-staff">
      <div class="title">
        <div class="text">対象予約列</div>
        <hint-balloon :note="reservationColumnDescription" />
        <div class="red">必須</div>
      </div>
      <div class="column-staff-area">
        <reservation-purpose-column-staff-area
          titleName="予約列"
          :parentRegisterData="registerReservationColumns"
          :parentStateData="stateReservationColumns"
          @click="setReservationColumns"
        />
      </div>
    </div>
    <div class="reservation-purpose-column-staff">
      <div class="title">
        <div class="text">対象スタッフ</div>
        <hint-balloon :note="staffDescription" />
        <div class="red">必須</div>
      </div>
      <div class="column-staff-area">
        <reservation-purpose-column-staff-area
          titleName="スタッフ"
          :parentRegisterData="registerStaffs"
          :parentStateData="stateStaffs"
          @click="setStaffs"
        />
      </div>
    </div>
    <div class="common-form time">
      <div class="heading">設定時間<span class="require">必須</span></div>
      <base-time-input-field
        :value="reservationPurpose.time"
        format="HH:mm"
        input-width="90px"
        :minuteInterval="5"
        :hourLabel="'時'"
        :minuteLabel="'分'"
        :hourRange="hourRange"
        :minuteRange="minuteRange"
        :hideDisabledMinutes="false"
        @input="inputTime"
      />
    </div>
    <div class="common-form">
      <radio-button-form
        v-bind="firstShowFlgData"
        :value="reservationPurpose.firstShowFlg"
        @input="inputFirstShowFlg"
      />
    </div>
    <div class="common-form">
      <radio-button-form
        v-bind="secondShowFlgData"
        :value="reservationPurpose.secondShowFlg"
        @input="inputSecondShowFlg"
      />
    </div>
    <div class="common-form">
      <select-box-form
        :selectData="selectNumber"
        :note="maxPatientsDescription"
        v-model.number="reservationPurpose.maxPatients"
      >
        患者上限数
      </select-box-form>
    </div>
    <div class="common-form">
      <color-input-form
        :requiredFlg="true"
        :color="reservationPurpose.color"
        :note="colorDescription"
        v-model="reservationPurpose.color"
        >カラー</color-input-form
      >
    </div>
    <div class="reception-time-title">
      <div class="text">受付時間設定</div>
      <hint-balloon :note="receptionTimeDescription" />
    </div>
    <div class="common-form">
      <radio-button-form
        v-bind="customFlgData"
        :value="reservationPurpose.customFlg"
        @input="inputCustomFlg"
      />
    </div>
  </div>
</template>

<script>
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import ReservationPurposeColumnStaffArea from '@/components/parts/organisms/ReservationPurposeColumnStaffArea'
import BaseTimeInputField from '@/components/parts/atoms/BaseTimeInputField'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import ColorInputForm from '@/components/parts/molecules/ColorInputForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationPurposeInputForm',
  components: {
    RadioButtonForm,
    TextInputForm,
    ReservationPurposeColumnStaffArea,
    BaseTimeInputField,
    SelectBoxForm,
    HintBalloon,
    ColorInputForm
  },

  props: {
    reservationPurpose: {
      type: Object
    },
    registerReservationColumns: {
      type: Array
    },
    stateReservationColumns: {
      type: Array
    },
    registerStaffs: {
      type: Array
    },
    stateStaffs: {
      type: Array
    }
  },

  data() {
    return {
      enAvailable: false,
      firstShowFlgData: {
        title: '未登録飼主表示',
        radioButtonData: [
          { id: 1, eachValue: 1, labelName: '表示' },
          { id: 2, eachValue: 0, labelName: '非表示' }
        ]
      },
      secondShowFlgData: {
        title: '登録済飼主表示',
        radioButtonData: [
          { id: 3, eachValue: 1, labelName: '表示' },
          { id: 4, eachValue: 0, labelName: '非表示' }
        ]
      },
      customFlgData: {
        title: '来院カスタム設定',
        radioButtonData: [
          { id: 5, eachValue: 1, labelName: '有効' },
          { id: 6, eachValue: 0, labelName: '無効' }
        ]
      },
      selectNumber: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 }
      ],
      reservationColumnDescription: `「医院設定 ＞ 予約の自動登録」が有効な際、優先度が高い予約列から自動登録されます。\n優先度は「↑」「↓」ボタンで変更できます。`,
      staffDescription: `「医院設定 ＞ 予約の自動登録」が有効な際、優先度が高いスタッフから自動登録されます。\n優先度は「↑」「↓」ボタンで変更できます。`,
      maxPatientsDescription: `1度の予約で登録できる患者の上限数`,
      receptionTimeDescription: `受付時間は「共通設定 ＞ 診療日設定 」で設定した診療時間が設定されます。\n設定した時間とは別の受付時間を設定する場合、下のカスタム設定を有効にしてください。`,
      colorDescription: `この来院目的を設定した予約に適用される色です。\n（注）予約の色は「表示設定」の「予約の色の優先順位」をもとに決まります。`
    }
  },

  computed: {
    ...mapGetters({
      reservationSetting: 'reservationSetting/getData'
    }),
    hourRange() {
      return [[0, 3]]
    },
    minuteRange() {
      if (this.reservationPurpose.time.HH === '03') {
        return [0]
      } else if (this.reservationPurpose.time.HH === '00') {
        return [[5, 55]]
      } else {
        return [[0, 55]]
      }
    }
  },

  mounted() {
    this.enAvailable = this.reservationSetting.englishOptionFlg === 1
  },

  methods: {
    inputName(val) {
      this.$emit('input', val, 'name')
    },
    inputNameEnglish(val) {
      this.$emit('input', val, 'nameEnglish')
    },
    inputTime(val) {
      this.$emit('input', val, 'time')
    },
    inputFirstShowFlg(val) {
      this.$emit('input', val, 'firstShowFlg')
    },
    inputSecondShowFlg(val) {
      this.$emit('input', val, 'secondShowFlg')
    },
    inputCustomFlg(val) {
      this.$emit('input', val, 'customFlg')
    },
    setReservationColumns(registerData, stateData, selectData, event) {
      this.$emit(
        'click',
        registerData,
        stateData,
        'reservationColumns',
        selectData,
        event
      )
    },
    setStaffs(registerData, stateData, selectData = null, event = '') {
      this.$emit('click', registerData, stateData, 'staffs', selectData, event)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-input-form {
  > .reservation-purpose-column-staff {
    margin-top: 30px;
    > .title {
      display: flex;
      align-items: center;
      > .red {
        margin-left: 5px;
        color: #{$tomato};
        font-size: 13px;
      }
    }
    > .column-staff-area {
      margin: 20px 0px 0px 80px;
    }
  }
  > .common-form {
    margin-top: 30px;
    width: 600px;
    &.time {
      display: flex;
      > .heading {
        width: 220px;
        margin-right: 20px;
        > .require {
          padding-left: 10px;
          font-size: 13px;
          color: #{$tomato};
        }
      }
    }
  }
  > .reception-time-title {
    display: flex;
    align-items: center;
    margin: 65px 0px 0px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    > .text {
      font-weight: bold;
    }
  }
}
</style>
