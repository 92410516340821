<template>
  <div class="calendar-table-date-panel">
    <div
      class="calendar-date-panel-box"
      :class="{
        holiday: publicHolidaysColorFlg,
        'exception-day': exceptionDayColorFlg,
        'before-today': beforeTodayFlg
      }"
      :title="selectValueName"
    >
      <div class="calendar-date-panel-day">
        {{ date | getDay }}
        <div class="holiday" v-if="publicHolidaysColorFlg">(祝日)</div>
      </div>
      <template v-if="hiddenFlg || beforeTodayFlg" />
      <template v-else-if="readOnlyFlg">
        <div class="calendar-date-panel-time">
          <div
            class="calendar-date-panel-text"
            :class="{
              'exception-day': exceptionDayColorFlg,
              holiday: publicHolidaysColorFlg
            }"
          >
            {{ selectValueName }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="calendar-date-panel-time">
          <base-select-box
            :selectData="selectDataList"
            :value="selectValue"
            @input="inputReservationAvailablePatternId"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'CalendarTableDatePanel',

  components: {
    BaseSelectBox
  },

  filters: {
    getDay: date => {
      if (date) {
        const day = Number(date.slice(6, 8))
        return day
      }
      return ''
    }
  },

  props: {
    date: { type: String, default: '' },
    selectDataList: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectValue: { type: Number, default: 0 },
    selectValueName: { type: String, default: '' },
    hiddenFlg: { type: Boolean, default: false },
    beforeTodayFlg: { type: Boolean, default: false },
    exceptionDayColorFlg: { type: Boolean, default: false },
    publicHolidaysColorFlg: { type: Boolean, default: false },
    readOnlyFlg: { type: Boolean, default: false },
    reservationPurpose: {
      type: Object,
      default: function() {
        return {}
      }
    },
    openTimes: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  computed: {
    ...mapGetters({
      publicHolidays: 'master/getPublicHolidays',
      reservationAvailablePatterns: 'reservationAvailablePatterns/getData'
    })
  },

  methods: {
    inputReservationAvailablePatternId(value) {
      const dayOfWeek = moment(this.date)
        .toDate()
        .getDay()
      if (Object.keys(this.reservationPurpose).length != 0) {
        const defaultReservationAvailablePatternsId =
          this.publicHolidays.indexOf(this.date) != -1
            ? this.reservationPurpose.reservationAvailablePatternsIds[7]
            : this.reservationPurpose.reservationAvailablePatternsIds[dayOfWeek]
        defaultReservationAvailablePatternsId === value
          ? this.$emit('input', {
              value: value,
              date: this.date,
              deleteFlg: true
            })
          : this.$emit('input', {
              value: value,
              date: this.date,
              deleteFlg: false,
              name: this.selectDataList.find(v => v.id === value).name
            })
      } else {
        const openTime =
          this.publicHolidays.indexOf(this.date) != -1
            ? this.openTimes.find(v => v.type === 8)
            : this.openTimes.find(v => v.type === dayOfWeek + 1)
        const defaultReservationAvailablePatternsId =
          openTime.closeFlg === 1
            ? this.reservationAvailablePatterns.find(v => v.closedDayFlg === 1)
                .id
            : this.reservationAvailablePatterns.find(v => v.baseTimeFlg === 1)
                .id
        defaultReservationAvailablePatternsId === value
          ? this.$emit('input', {
              value: value,
              date: this.date,
              deleteFlg: true
            })
          : this.$emit('input', {
              value: value,
              date: this.date,
              deleteFlg: false,
              name: this.selectDataList.find(v => v.id === value).name
            })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-table-date-panel {
  > .calendar-date-panel-box {
    position: relative;
    margin: 3.5px auto;
    padding: 0;
    width: 94%;
    height: 120px;
    border: none;
    background-color: #{$white_f7};
    &.holiday {
      background-color: #{$light-purple};
    }
    &.exception-day {
      background-color: #{$pumpkin};
      color: #{$white};
    }
    &.before-today {
      background-color: #{$light-grey};
      color: #{$brown-gray};
    }
    > .calendar-date-panel-day {
      position: absolute;
      top: 18px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      > .holiday {
        font-size: 13px;
      }
    }
    > .calendar-date-panel-time {
      position: absolute;
      top: 64px;
      left: 0;
      right: 0;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      > .calendar-date-panel-text {
        background-color: #{$white};
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.exception-day {
          color: #{$greyish-brown};
        }
        &.holiday {
          color: #{$greyish-brown};
        }
      }
    }
  }
}
</style>
