<template>
  <div class="reservation-available-pattern-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">受付時間パターン編集</div>
      <div class="setting-content">
        <div class="content-title">基本設定</div>
        <div v-if="closedDayOrBaseTimeFlg" class="error-message">
          「休診日」と「基本診療時間」の受付時間パターンデータは編集できません
        </div>
        <div class="content">
          <reservation-available-pattern-input-form
            :reservation-available-pattern="reservationAvailablePattern"
            @input-name="inputName"
            @input-start-time="inputStartTime"
            @input-end-time="inputEndTime"
            @add-pattern="addPattern"
            @del-pattern="delPattern"
          />
        </div>
        <div class="button-area">
          <base-button-medium-white class="button" @click="backShowPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="
              invalid || !allValidFlg || closedDayOrBaseTimeFlg || waitFlg
            "
            @click="createReservationAvailablePattern"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="openShowPage"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ReservationAvailablePatternInputForm from '@/components/parts/organisms/ReservationAvailablePatternInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import {
  validateTimes,
  validTimePeriod,
  validTimePatterns
} from '@/utils/reservation_available_pattern'

export default {
  name: 'ReservationAvailablePatternEdit',

  components: {
    ReservationAvailablePatternInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      reservationAvailablePattern: {},
      alertFlg: false,
      waitFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '編集しました',
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getReservationAvailablePattern:
        'reservationAvailablePatterns/getDataById',
      openTimes: 'openTimes/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    timeValidFlg() {
      return validateTimes(this.convertTimes)
    },
    periodValidFlg() {
      return validTimePeriod(this.convertTimes)
    },
    patternValidFlg() {
      return validTimePatterns(this.convertTimes)
    },
    allValidFlg() {
      return this.timeValidFlg && this.periodValidFlg && this.patternValidFlg
    },
    convertTimes() {
      return this.reservationAvailablePattern.times.map(v => {
        return {
          startTime: this.toTime(v.startTime),
          endTime: this.toTime(v.endTime)
        }
      })
    },
    closedDayOrBaseTimeFlg() {
      return this.reservationAvailablePattern.closedDayFlg === 1 ||
        this.reservationAvailablePattern.baseTimeFlg === 1
        ? true
        : false
    }
  },

  created() {
    this.reservationAvailablePattern = _.cloneDeep(
      this.getReservationAvailablePattern(this.id)
    )
    this.reservationAvailablePattern.times =
      this.reservationAvailablePattern.times instanceof Array
        ? this.reservationAvailablePattern.times.map((time, index) => {
            return {
              key: index + 1,
              startTime: this.insertColon(time.slice(0, 4)),
              endTime: this.insertColon(time.slice(4, 8))
            }
          })
        : [
            {
              key: 1,
              startTime: this.insertColon(
                this.reservationAvailablePattern.times.slice(0, 4)
              ),
              endTime: this.insertColon(
                this.reservationAvailablePattern.times.slice(4, 8)
              )
            }
          ]
    this.mixinInputData = this.reservationAvailablePattern
    this.mixinSetInitialData()
  },

  methods: {
    insertColon(str) {
      return Number(str.slice(0, 2)) + ':' + str.slice(2, 4)
    },
    toTime(colonTime) {
      return ('0000' + colonTime.split(':').join('')).slice(-4)
    },
    inputName(val) {
      this.reservationAvailablePattern.name = val
    },
    inputStartTime(obj) {
      this.$set(
        this.reservationAvailablePattern.times[obj.targetIndex],
        'startTime',
        obj.val
      )
    },
    inputEndTime(obj) {
      this.$set(
        this.reservationAvailablePattern.times[obj.targetIndex],
        'endTime',
        obj.val
      )
    },
    addPattern() {
      const lastObject = this.reservationAvailablePattern.times[
        this.reservationAvailablePattern.times.length - 1
      ]
      this.reservationAvailablePattern.times.push({
        key: lastObject.key + 1,
        startTime: lastObject.endTime,
        endTime: this.insertColon(this.openTimes.find(v => v.type === 0).end)
        // type=> 0:base,1:sunday,2:monday,3:tuesday,4:wednesday,5:thursday,6:friday,7:saturday,8:holiday
      })
    },
    delPattern(targetIndex) {
      this.reservationAvailablePattern.times.splice(targetIndex, 1)
    },
    backShowPage() {
      this.$router.push({
        name: 'reservation-available-pattern-show',
        params: { id: this.id }
      })
    },
    async createReservationAvailablePattern() {
      this.waitFlg = true
      const sendData = _.cloneDeep(this.reservationAvailablePattern)
      sendData.times = sendData.times
        .map(v => this.toTime(v.startTime) + this.toTime(v.endTime))
        .join(',')
      const result = await this.$store.dispatch(
        'reservationAvailablePatterns/update',
        sendData
      )
      if (result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        if (
          result === 'no reservationAvailablePattern' ||
          result === 'no data in clinic'
        ) {
          this.popupMessage =
            '編集に失敗しました。\n編集中の受付時間パターンは既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    openShowPage() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({
          name: 'reservation-available-pattern-show',
          params: { id: this.id }
        })
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'reservation-available-patterns' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-available-pattern-edit {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      margin-top: 20px;
      > .content-title {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .error-message {
        margin-top: 20px;
        font-size: 15px;
        color: #{$tomato};
      }
      > .content {
        margin-top: 26px;
      }
      > .button-area {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
