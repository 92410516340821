<template>
  <div class="reservation-setting-base">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="reservation-setting-base-form">
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="netAvailableFlgData"
            v-model="reservationSetting.netAvailableFlg"
          />
        </div>
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="firstAvailableFlgData"
            v-model="reservationSetting.firstAvailableFlg"
          />
        </div>
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="autoConfirmFlgData"
            v-model="reservationSetting.autoConfirmFlg"
          />
        </div>
        <reservation-setting-period-form
          :reservation-setting="reservationSetting"
          :invalidPeriodFlg="invalidPeriodFlg"
          v-model="reservationSetting.reservationAvailablePeriodType"
          @input-reservation-setting="inputReservationSetting"
        />
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="reservationAvailableNoticeFlgData"
            v-model="reservationSetting.reservationAvailableNoticeFlg"
            :title-styles="{ height: 'auto' }"
          />
        </div>
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="reservationTimeUnitData"
            v-model="reservationSetting.reservationTimeUnit"
          />
        </div>
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="cancelableFlgData"
            v-model="reservationSetting.cancelableFlg"
          />
        </div>
        <div class="select-box-form-area" data-e2e="select-box-form-area">
          <div class="title">予約変更・キャンセル可能期間</div>
          <base-select-box
            :selectData="selectData"
            v-model="reservationSetting.reservationChangeCancelAvailablePeriod"
          />
          <div class="following-text">日前まで変更・キャンセルが可能</div>
        </div>
        <div class="select-box-form-area">
          <div class="title">
            <div class="text">飼主予約の上限数</div>
            <hint-balloon
              class="balloon"
              :note="
                '飼主様がネット予約できる上限数の設定です。\n医院様からは無制限に予約できます。'
              "
            />
          </div>
          <base-select-box
            :selectData="reservationCapSelectData"
            v-model="reservationSetting.ownerReservationCap"
          />
        </div>
        <reservation-setting-link-area />
        <reservation-setting-text-form
          :label="'お知らせ'"
          :note="'予約申請ページにて、飼主様へのお知らせを表示できます。'"
          :maxlength="65535"
          v-model="reservationSetting.netNotice"
        />
        <div class="radio-button-form-area">
          <radio-button-form
            v-bind="memoRequiredFlgData"
            v-model="reservationSetting.memoRequiredFlg"
          />
        </div>
        <reservation-setting-text-form
          :label="'備考注意書き'"
          :note="
            '予約入力ページの備考欄にて、飼主様への注意書きを表示できます。（120文字まで）'
          "
          :maxlength="120"
          v-model="reservationSetting.memoNotice"
        />
        <div class="radio-button-form-area" v-if="!productionMode">
          <radio-button-form
            v-bind="englishOptionFlgData"
            v-model="reservationSetting.englishOptionFlg"
          />
        </div>
        <div
          class="english-option-form"
          v-if="reservationSetting.englishOptionFlg === 1 && !productionMode"
        >
          <reservation-setting-text-form
            :label="'お知らせ（英語）'"
            :note="
              '英語版の予約申請ページにて、飼主様へのお知らせを表示できます。'
            "
            :maxlength="65535"
            v-model="reservationSetting.netNoticeEnglish"
          />
          <reservation-setting-text-form
            :label="'備考注意書き（英語）'"
            :note="
              '英語版の予約入力ページの備考欄にて、飼主様への注意書きを表示できます。（120文字まで）'
            "
            :maxlength="120"
            v-model="reservationSetting.memoNoticeEnglish"
          />
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="invalid || waitFlg || invalidPeriodFlg"
          @click="commit"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="closeAlert"
        :title="title"
        :buttons="buttons"
        :type="type"
        :leftAlignMessage="leftAlignMessage"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>
<script>
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import ReservationSettingPeriodForm from '@/components/parts/organisms/ReservationSettingPeriodForm.vue'
import ReservationSettingLinkArea from '@/components/parts/organisms/ReservationSettingLinkArea.vue'
import ReservationSettingTextForm from '@/components/parts/organisms/ReservationSettingTextForm'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import { removeClinicIdDelCreateUpdate } from '@/utils/property_filter'

export default {
  name: 'ReservationSettingBase',

  components: {
    RadioButtonForm,
    BaseSelectBox,
    ReservationSettingPeriodForm,
    BaseButtonMediumOrange,
    ReservationSettingLinkArea,
    ReservationSettingTextForm,
    HintBalloon,
    UnsavedLeavePopup,
    AnnouncePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      reservationSetting: {},
      netAvailableFlgData: {
        title: '予約の受付',
        radioButtonData: [
          { id: 1, eachValue: 1, labelName: '有効' },
          { id: 2, eachValue: 0, labelName: '無効' }
        ]
      },
      firstAvailableFlgData: {
        title: '初診の予約受付',
        radioButtonData: [
          { id: 3, eachValue: 1, labelName: '有効' },
          { id: 4, eachValue: 0, labelName: '無効' }
        ]
      },
      autoConfirmFlgData: {
        title: '予約の自動登録',
        radioButtonData: [
          { id: 5, eachValue: 1, labelName: '有効' },
          { id: 6, eachValue: 0, labelName: '無効' }
        ]
      },
      reservationAvailableNoticeFlgData: {
        title: '予約可能期間の通知',
        titleDescription: '有効にした場合、1週間前に通知を行います。',
        radioButtonData: [
          { id: 9, eachValue: 1, labelName: '有効' },
          { id: 10, eachValue: 0, labelName: '無効' }
        ]
      },
      reservationTimeUnitData: {
        title: '予約時間枠',
        radioButtonData: [
          { id: 11, eachValue: 10, labelName: '10分' },
          { id: 12, eachValue: 15, labelName: '15分' },
          { id: 13, eachValue: 20, labelName: '20分' },
          { id: 14, eachValue: 30, labelName: '30分' },
          { id: 15, eachValue: 60, labelName: '60分' }
        ]
      },
      cancelableFlgData: {
        title: '予約変更・キャンセル受付',
        radioButtonData: [
          { id: 16, eachValue: 1, labelName: '有効' },
          { id: 17, eachValue: 0, labelName: '無効' }
        ]
      },
      // radioButtonData id:18,19はReservationSettingPeriodForm.vueのラジオボタンで使用している
      memoRequiredFlgData: {
        title: '備考の必須設定',
        radioButtonData: [
          { id: 20, eachValue: 1, labelName: '必須' },
          { id: 21, eachValue: 0, labelName: '任意' }
        ]
      },
      englishOptionFlgData: {
        title: '予約ページの英語選択',
        radioButtonData: [
          { id: 22, eachValue: 1, labelName: '有効' },
          { id: 23, eachValue: 0, labelName: '無効' }
        ]
      },
      alertFlg: false,
      waitFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '編集しました',
      leftAlignMessage: '',
      selectData: [
        { id: 0, name: 0 },
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 }
      ]
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    productionMode() {
      return process.env.NODE_ENV === 'production'
    },
    invalidPeriodFlg() {
      if (this.reservationSetting.reservationAvailablePeriodType === 2)
        return (
          this.reservationSetting.reservationStartDate >
          this.reservationSetting.reservationEndDate
        )
      else return false
    },
    reservationCapSelectData() {
      return [{ id: 0, name: '無制限' }].concat(
        [...new Array(10).keys()].map(v => {
          return { id: v + 1, name: v + 1 }
        })
      )
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      const data = removeClinicIdDelCreateUpdate(
        _.cloneDeep(this.$store.getters['reservationSetting/getData'])
      )
      if (data.reservationEndPeriod === '') {
        if (data.reservationStartAfterUnit === 1) {
          data.reservationEndPeriod = moment()
            .add(data.reservationStartAfterNumber, 'days')
            .format('YYYYMMDD')
        } else {
          data.reservationEndPeriod = moment().format('YYYYMMDD')
        }
      }
      if (data.reservationStartDate === '')
        data.reservationStartDate = moment().format('YYYYMMDD')
      if (data.reservationEndDate === '')
        data.reservationEndDate = moment().format('YYYYMMDD')
      this.reservationSetting = { ...data }
      this.mixinInputData = this.reservationSetting
      this.mixinSetInitialData()
    },
    inputReservationSetting(val, property) {
      this.reservationSetting[property] = val
    },
    async commit() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'reservationSetting/update',
        this.reservationSetting
      )
      if (res.result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '編集しました'
        this.leftAlignMessage = res.leftAlignMessage
        this.setData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage = '編集に失敗しました'
        this.leftAlignMessage = ''
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-setting-base {
  min-width: 810px;
  > .observer {
    > .reservation-setting-base-form {
      margin-top: 40px;
      font-size: 15px;
      > .radio-button-form-area {
        margin-bottom: 30px;
      }
      > .select-box-form-area {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        > .title {
          display: flex;
          align-items: center;
          width: 220px;
          margin-right: 20px;
        }
        > .following-text {
          margin-left: 10px;
        }
      }
    }
    > .button-area {
      display: flex;
      justify-content: center;
      margin: 60px 0;
    }
  }
}
</style>
