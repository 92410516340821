import { render, staticRenderFns } from "./ReservationPurposeInputForm.vue?vue&type=template&id=0efa6f8f&scoped=true&"
import script from "./ReservationPurposeInputForm.vue?vue&type=script&lang=js&"
export * from "./ReservationPurposeInputForm.vue?vue&type=script&lang=js&"
import style0 from "./ReservationPurposeInputForm.vue?vue&type=style&index=0&id=0efa6f8f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0efa6f8f",
  null
  
)

export default component.exports