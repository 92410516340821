<template>
  <div
    class="reservation-purpose-list-box"
    data-e2e="reservation-purpose-list-box"
  >
    <div
      class="list-item"
      :class="{ selected: datum.id === selectDataId }"
      v-for="datum in data"
      :key="datum.id"
      @click="handleClick(datum)"
      @dblclick="handleDoubleClilck(datum)"
      data-e2e="reservation-purpose-list-item"
    >
      {{ datum.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationPurposeListBox',

  props: {
    data: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectDataId: {
      type: Number
    }
  },

  methods: {
    handleClick(datum) {
      this.$emit('click', datum)
    },
    handleDoubleClilck(datum) {
      this.$emit('double-click', datum)
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-list-box {
  margin-top: 12px;
  width: 210px;
  height: 200px;
  overflow-y: auto;
  white-space: nowrap;
  border-radius: 4px;
  border: solid 2px #{$light-grey};
  > .list-item {
    cursor: pointer;
    &.selected {
      background-color: #{$robin-s-egg};
    }
  }
}
</style>
