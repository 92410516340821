<template>
  <div class="reservation-available-pattern-list-table">
    <div
      v-for="(title, index) in titleData"
      :key="index"
      class="table-body-row"
    >
      <div class="row-item title">{{ title }}</div>
      <div class="row-item">{{ bodyData[index] }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationAvailablePatternListTable',
  props: {
    titleData: {
      type: Array,
      default: function() {
        return []
      }
    },
    bodyData: {
      type: Array,
      default: function() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-available-pattern-list-table {
  width: 100%;
  font-size: 15px;
  border: 1px solid #{$light-grey};
  text-align: left;
  > .table-body-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #{$light-grey};
    &:last-child {
      border: none;
    }
    > .row-item {
      width: 100%;
      padding: 15px 12px;
      &.title {
        max-width: 203px;
        background-color: #{$pale};
      }
    }
  }
}
</style>
