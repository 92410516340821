<template>
  <div class="reservation-purpose-show">
    <div class="link-area">
      <base-back-link @click="backListPage" />
    </div>
    <div class="setting-title">来院目的詳細</div>
    <div class="setting-content">
      <div class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0 && !lastDataFlg"
          @click="openAlert"
          >削除</base-button-small-red
        >
      </div>
      <div class="basic-setting-title">基本設定</div>
      <show-data-list
        :display-keys="reservationPurposeKeyNames"
        :keys="reservationPurposeKeys"
        :data="displayReservationPurpose"
      />
      <div class="reception-time-title">受付時間設定</div>
      <div class="reception-time-text">
        受付時間は「共通設定 ＞ 診療日設定
        」で設定した診療時間が設定されます。<br />
        設定した時間とは別の受付時間を設定する場合、下のカスタム設定を有効にしてください。
      </div>
      <show-data-list
        :display-keys="customKeyNames"
        :keys="customKeys"
        :data="displayReservationPurpose"
      />
      <reservation-purpose-custom-area
        v-if="displayReservationPurpose.customFlg"
        :reservation-purpose="displayReservationPurpose"
        :special-reservation-available-patterns="
          specialReservationAvailablePatterns
        "
        @open-patterns-page="openPatternsPage"
      />
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="alertFlg = false"
      @decision="deletePurpose"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import ReservationPurposeCustomArea from '@/components/parts/organisms/ReservationPurposeCustomArea.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationPurposeShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    ReservationPurposeCustomArea,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      customKeyNames: ['来院カスタム設定'],
      customKeys: ['custom'],
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['閉じる', '削除'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？',
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      reservationPurposes: 'reservationPurposes/getData',
      reservationSetting: 'reservationSetting/getData',
      getReservationColumn: 'reservationColumns/getDataById',
      getStaff: 'staffs/getDataById',
      specialReservationAvailablePatterns:
        'specialReservationAvailablePatterns/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    reservationPurposeKeyNames() {
      const keyNamesEnglishEnabled = [
        '来院目的名',
        '来院目的名（英語）',
        '予約列',
        'スタッフ',
        '時間',
        '未登録飼主表示',
        '登録済飼主表示',
        '患者上限数\n(1度の予約で登録できる\n患者の上限数)',
        'カラー'
      ]
      const keyNamesEnglishDisabled = [
        '来院目的名',
        '予約列',
        'スタッフ',
        '時間',
        '未登録飼主表示',
        '登録済飼主表示',
        '患者上限数\n(1度の予約で登録できる\n患者の上限数)',
        'カラー'
      ]
      return this.reservationSetting.englishOptionFlg === 1
        ? keyNamesEnglishEnabled
        : keyNamesEnglishDisabled
    },
    reservationPurposeKeys() {
      const keysEnglishEnabled = [
        'name',
        'nameEnglish',
        'reservationColumnsNames',
        'staffsNames',
        'timeMinutes',
        'firstShow',
        'secondShow',
        'maxPatients',
        'color'
      ]
      const keysEnglishDisabled = [
        'name',
        'reservationColumnsNames',
        'staffsNames',
        'timeMinutes',
        'firstShow',
        'secondShow',
        'maxPatients',
        'color'
      ]
      return this.reservationSetting.englishOptionFlg === 1
        ? keysEnglishEnabled
        : keysEnglishDisabled
    },
    lastDataFlg() {
      return this.reservationPurposes.length === 1
    },
    reservationPurpose() {
      return this.$store.getters['reservationPurposes/getDataById'](this.id)
    },
    displayReservationPurpose() {
      const reservationPurpose = this.reservationPurpose
      if (reservationPurpose) {
        const reservationColumnsNames = reservationPurpose.reservationColumnsIds
          .map(columnId => this.getReservationColumn(columnId).name)
          .join('、')
        const staffsNames = reservationPurpose.staffsIds
          .map(staffId => {
            const staff = this.getStaff(staffId)
            return staff.lastName + staff.firstName
          })
          .join('、')
        return {
          ...reservationPurpose,
          reservationColumnsNames: reservationColumnsNames,
          staffsNames: staffsNames,
          timeMinutes: `${reservationPurpose.time}分`,
          firstShow: reservationPurpose.firstShowFlg == 1 ? '表示' : '非表示',
          secondShow: reservationPurpose.secondShowFlg == 1 ? '表示' : '非表示',
          custom: reservationPurpose.customFlg == 1 ? '有効' : '無効'
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    backListPage() {
      this.$router.push({ name: 'reservation-purposes' })
    },
    openEditPage() {
      this.$router.push({
        name: 'reservation-purpose-edit',
        params: { id: this.id }
      })
    },
    openPatternsPage() {
      this.$router.push({ name: 'reservation-available-patterns' })
    },
    initializeAlert() {
      this.title = '注意'
      this.buttons = ['閉じる', '削除']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    openAlert() {
      this.initializeAlert()
      this.alertFlg = true
    },
    async deletePurpose() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationPurposes/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'last reservationPurpose') {
          this.popupMessage = '来院目的が残り一つのため削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.$router.push({ name: 'reservation-purposes' })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-show {
  > .link-area {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin: 20px 0;
  }
  > .setting-content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .basic-setting-title {
      font-size: 15px;
      font-weight: bold;
      margin-top: 16px;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
    > .reception-time-title {
      font-size: 15px;
      font-weight: bold;
      margin: 50px 0px 17px;
    }
    > .reception-time-text {
      font-size: 13px;
      line-height: 1.54;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
  }
}
</style>
