const validateTime = time => {
  if (typeof time === 'string' && time.length === 4) {
    const h = parseInt(time.slice(0, 2))
    const m = parseInt(time.slice(2, 4))
    return 0 <= h && h <= 23 && 0 <= m && m <= 59
  } else {
    return false
  }
}

const validateTimes = times => {
  return times.every(o => validateTime(o.startTime) && validateTime(o.endTime))
}

const validTimePeriod = times => {
  return times.every(o => o.startTime < o.endTime)
}

const validTimePatterns = times => {
  return times.every((o, index) => {
    if (index === times.length - 1) {
      return true
    } else {
      return o.endTime <= times[index + 1].startTime
    }
  })
}

module.exports = {
  validateTimes,
  validTimePeriod,
  validTimePatterns
}
