<template>
  <div class="list-table-show" :style="tableStyles" data-e2e="list-table-show">
    <div class="table-header" v-if="headerData">
      <div class="row-item" v-for="(item, index) in headerData" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="table-body" :style="bodyStyles">
      <div
        class="table-body-row"
        v-for="(row, index) in propertyFilterBodyData"
        :key="index"
      >
        <div class="row-item" v-for="(item, name, i) in row" :key="i">
          <template v-if="selectDataList.length != 0 && i === 1">
            <base-select-box
              :selectData="selectDataList"
              :value="bodyData[index].id"
              :styles="selectBoxStyles"
              @input="input($event, index)"
            />
          </template>
          <template v-else>
            <span :title="item">{{ item }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
export default {
  name: 'ListTableShow',
  components: {
    BaseSelectBox
  },
  props: {
    headerData: { type: Array },
    bodyData: {
      type: Array
    },
    propertyData: {
      type: Array,
      validator: function(arr) {
        return arr.every(v => typeof v === 'string')
      }
    },
    tableStyles: {
      width: { type: String },
      maxWidth: { type: String },
      minWidth: { type: String },
      textAlign: { type: String }
    },
    bodyStyles: {
      height: { type: String }
    },
    selectDataList: {
      type: Array,
      default: function() {
        return []
      }
    },
    selectValue: { type: Number }
  },

  data() {
    return {
      selectBoxStyles: { width: '150px' }
    }
  },

  computed: {
    propertyFilterBodyData() {
      return this.bodyData.map(obj => {
        const returnObj = {}
        this.propertyData.forEach(propertyName => {
          returnObj[propertyName] = obj[propertyName]
        })
        return returnObj
      })
    }
  },
  methods: {
    input(value, index) {
      this.$emit('input', {
        selectId: value,
        targetIndex: index
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table-show {
  font-size: 15px;
  border: 1px solid #{$light-grey};
  text-align: center;
  > .table-header {
    display: flex;
    height: 40px;
    background-color: #{$pale};
    align-items: center;
    > .row-item {
      width: 50%;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: 1px solid #{$skin};
      line-height: 40px;
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
  > .table-body {
    overflow-y: auto;
    > .table-body-row {
      display: flex;
      height: 56px;
      align-items: center;
      &:nth-child(even) {
        background-color: #{$white_f7};
      }
      > .row-item {
        width: 50%;
        padding: 0 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
