<template>
  <div class="reservation-setting-text-form">
    <div class="label-wrap">
      <div class="label" data-test="label">
        <div class="text">{{ label }}</div>
        <hint-balloon :note="note" />
      </div>
    </div>
    <div class="content" data-test="note">
      <div class="text-box-area">
        <validation-provider
          class="validation-provider"
          :rules="{ notSurrogatePair: true }"
          v-slot="{ errors }"
        >
          <base-multiple-lines-text-box
            :value="value"
            @input="inputText"
            :styles="boxStyles"
            :maxlength="maxlength"
          />
          <div class="error">{{ errors[0] }}</div>
        </validation-provider>
      </div>
    </div>
  </div>
</template>
<script>
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import HintBalloon from '@/components/parts/atoms/HintBalloon'

export default {
  name: 'ReservationSettingTextForm',
  components: {
    BaseMultipleLinesTextBox,
    ValidationProvider,
    HintBalloon
  },
  props: {
    value: { type: String, required: true },
    label: { type: String, required: true },
    note: { type: String, required: true },
    maxlength: { type: Number }
  },
  data() {
    return {
      boxStyles: {
        width: '498px',
        height: '120px'
      }
    }
  },
  methods: {
    inputText(val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-setting-text-form {
  display: flex;
  margin-bottom: 30px;
  > .label-wrap {
    > .label {
      width: 240px;
      height: 33px;
      display: flex;
      align-items: center;
    }
  }
  > .content {
    font-size: 13px;
    > .text-box-area {
      @include validate-message();
    }
  }
}
</style>
