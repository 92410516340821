<template>
  <div class="reservation-setting-period-form-repeat">
    <div class="content-form">
      <base-select-box
        v-if="reservationStartAfterUnit === 1"
        :styles="miniBoxStyles"
        :value="reservationStartAfterNumber"
        :selectData="startAfterDate"
        :disabled="repeatFormDisabled"
        @input="inputStartAfterNumber"
        data-test="start-after-number-date"
      />
      <base-select-box
        v-if="reservationStartAfterUnit === 2"
        :styles="miniBoxStyles"
        :value="reservationStartAfterNumber"
        :selectData="startAfterTime"
        :disabled="repeatFormDisabled"
        @input="inputStartAfterNumber"
        data-test="start-after-number-time"
      />
      <div class="form-box">
        <base-select-box
          :styles="middleBoxStyles"
          :value="reservationStartAfterUnit"
          :selectData="startAfterUnit"
          :disabled="repeatFormDisabled"
          @input="inputStartAfterUnit"
          data-test="start-after-unit"
        />
      </div>
      <div class="text-area">から</div>
      <base-date-picker
        v-if="reservationEndPeriodUnit === 1"
        :value="endPeriodDate"
        :disabledDates="dates"
        :disabled="repeatFormDisabled"
        @input="inputEndPeriod"
        data-test="end-period-date"
      />
      <base-select-box
        v-if="reservationEndPeriodUnit === 2"
        :styles="miniBoxStyles"
        :value="reservationEndAfterNumber"
        :selectData="endAfterNumber"
        :disabled="repeatFormDisabled"
        @input="inputEndAfterNumber"
        data-test="end-after-number"
      />
      <div class="form-box">
        <base-select-box
          :styles="largeBoxStyles"
          :value="reservationEndPeriodUnit"
          :selectData="endPeriodUnit"
          :disabled="repeatFormDisabled"
          @input="inputEndPeriodUnit"
          data-test="end-period-unit"
        />
      </div>
      <div class="text-area">予約可能</div>
    </div>
  </div>
</template>

<script>
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import moment from 'moment'

export default {
  name: 'ReservationSettingPeriodFormRepeat',

  components: {
    BaseDatePicker,
    BaseSelectBox
  },

  props: {
    reservationAvailablePeriodType: {
      type: Number
    },
    reservationStartAfterNumber: {
      type: Number
    },
    reservationStartAfterUnit: {
      type: Number
    },
    reservationEndPeriod: {
      type: String
    },
    reservationEndAfterNumber: {
      type: Number
    },
    reservationEndPeriodUnit: {
      type: Number
    }
  },

  data() {
    return {
      dates: {
        years: [...Array(2020).keys()]
      },
      startAfterDate: [
        { id: 0, name: '0' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' }
      ],
      startAfterTime: [
        { id: 0, name: '0' },
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' }
      ],
      startAfterUnit: [
        { id: 1, name: '日後' },
        { id: 2, name: '時間後' }
      ],
      endAfterNumber: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' }
      ],
      endPeriodUnit: [
        { id: 1, name: 'まで' },
        { id: 2, name: 'ヶ月後まで' }
      ],
      largeBoxStyles: { width: '110px' },
      middleBoxStyles: { width: '85px' },
      miniBoxStyles: { width: '60px' }
    }
  },

  computed: {
    repeatFormDisabled() {
      return this.reservationAvailablePeriodType !== 1 ? true : false
    },
    endPeriodDate() {
      return this.reservationEndPeriod === ''
        ? moment().format('YYYYMMDD')
        : this.reservationEndPeriod
    }
  },

  methods: {
    inputStartAfterNumber(val) {
      this.$emit('input', val, 'reservationStartAfterNumber')
    },
    inputStartAfterUnit(val) {
      this.$emit('input', val, 'reservationStartAfterUnit')
    },
    inputEndPeriod(val) {
      this.$emit('input', val, 'reservationEndPeriod')
    },
    inputEndAfterNumber(val) {
      this.$emit('input', val, 'reservationEndAfterNumber')
    },
    inputEndPeriodUnit(val) {
      this.$emit('input', val, 'reservationEndPeriodUnit')
    }
  }
}
</script>

<style lang="scss" scpoed>
.reservation-setting-period-form-repeat {
  > .content-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 30px;
    > .form-box {
      margin-left: 10px;
    }
    > .text-area {
      line-height: 33px;
      margin: 0px 10px;
    }
  }
}
</style>
