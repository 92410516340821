<template>
  <div class="reservation-setting-link-area">
    <div class="title">
      <div class="text">予約申請ページ</div>
      <hint-balloon
        :note="
          '予約ページを貴院のホームページに設置する場合は以下のhtmlタグをコピーしてご利用ください。'
        "
      />
    </div>
    <div class="content">
      <div class="plus-net-link-text">
        <!-- 本番環境のURLに後ほど修正する -->
        &lt;a href='{{ plusNetLoginTopUrl }}'&gt;予約システム&lt;/a&gt;
      </div>
      <p>リンク</p>
      <a :href="plusNetLoginTopUrl" target="_blank" rel="noopener noreferrer">{{
        plusNetLoginTopUrl
      }}</a>
      <p>予約申請ページQRコード</p>
      <div class="qr-code-row">
        <qrcode-vue
          :value="plusNetLoginTopUrl"
          level="H"
          class="net-qr-code"
        ></qrcode-vue>
        <base-button-small-white @click="setClipboard()"
          >画像をコピー</base-button-small-white
        >
      </div>
      <div class="qr-code-row">
        <p class="notification">
          {{ qrCodeCopyMessage }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'

export default {
  name: 'ReservationSettingLinkArea',
  components: {
    HintBalloon,
    BaseButtonSmallWhite,
    QrcodeVue
  },
  data() {
    return {
      qrCodeCopyStatus: ''
    }
  },
  computed: {
    ...mapGetters({
      encryptedClinicId: 'clinic/getEncryptedClinicId'
    }),
    plusNetLoginTopUrl() {
      return (
        process.env.VUE_APP_DEV_PLUS_CLINIC_URL +
        '/login-top/?clinic=' +
        this.encryptedClinicId
      )
    },
    qrCodeCopyMessage() {
      return this.qrCodeCopyStatus === 'success'
        ? '画像をコピーしました'
        : this.qrCodeCopyStatus === 'failure'
        ? 'コピー失敗しました'
        : ''
    }
  },
  methods: {
    setClipboard() {
      try {
        document.querySelector('canvas').toBlob(async blob => {
          await navigator.clipboard.write([
            new window.ClipboardItem({
              'image/png': blob
            })
          ])
        })
        this.qrCodeCopyStatus = 'success'
      } catch (err) {
        this.qrCodeCopyStatus = 'failure'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-setting-link-area {
  margin-bottom: 30px;
  > .title {
    width: 220px;
    display: flex;
    align-items: center;
  }
  > .content {
    margin-top: 10px;
    font-size: 13px;
    line-height: 1.85;
    > .plus-net-link-text {
      word-break: break-all;
      width: 670px;
      margin-top: 10px;
      padding: 6px 8px;
      border-radius: 4px;
      border: solid 2px #{$light-grey};
    }
    > .qr-code-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;
      > .notification {
        font-size: 11px;
        height: 11px;
      }
    }
    > a {
      color: blue;
      text-decoration: underline;
      @include hover();
    }
  }
}
</style>
