<template>
  <div class="reservation-setting-period-form">
    <div class="title">
      予約可能期間
    </div>
    <div class="content">
      <base-radio-button
        :option="radioButtonData1"
        :value="value"
        @input="inputAvailablePeriodType"
      />
      <reservation-setting-period-form-repeat
        v-bind="reservationSetting"
        @input="inputReservationSetting"
      />

      <base-radio-button
        :option="radioButtonData2"
        :value="value"
        @input="inputAvailablePeriodType"
      />
      <reservation-setting-period-form-date
        v-bind="reservationSetting"
        :invalidPeriodFlg="invalidPeriodFlg"
        @input="inputReservationSetting"
      />
    </div>
  </div>
</template>

<script>
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import ReservationSettingPeriodFormRepeat from '@/components/parts/molecules/ReservationSettingPeriodFormRepeat.vue'
import ReservationSettingPeriodFormDate from '@/components/parts/molecules/ReservationSettingPeriodFormDate.vue'

export default {
  name: 'ReservationSettingPeriodForm',

  components: {
    BaseRadioButton,
    ReservationSettingPeriodFormRepeat,
    ReservationSettingPeriodFormDate
  },

  props: {
    reservationSetting: {
      type: Object
    },
    value: {
      type: Number, // reservationSetting.reservationAvailablePeriodTypeの値を入れる
      required: true
    },
    invalidPeriodFlg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      radioButtonData1: { id: 18, eachValue: 1, labelName: '繰り返し' },
      radioButtonData2: { id: 19, eachValue: 2, labelName: '日付を指定' }
    }
  },

  methods: {
    inputReservationSetting(val, property) {
      this.$emit('input-reservation-setting', val, property)
    },
    inputAvailablePeriodType(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scpoed>
.reservation-setting-period-form {
  display: flex;
  font-size: 15px;
  margin-bottom: 30px;
  > .title {
    width: 220px;
    margin-right: 20px;
  }
}
</style>
