<template>
  <div class="reservation-available-pattern-show">
    <div class="link-area">
      <base-back-link @click="backListPage" />
    </div>
    <div class="setting-title">受付時間パターン詳細</div>
    <div class="setting-content">
      <div v-if="!data.closedDayFlg && !data.baseTimeFlg" class="button-area">
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openEditPage"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openAlert"
          >削除</base-button-small-red
        >
      </div>
      <div class="content-title">
        基本設定
        <div v-if="data.closedDayFlg" class="content-title-text">
          ※パターン名「休診日」は編集と削除ができません。
        </div>
        <div v-if="data.baseTimeFlg" class="content-title-text">
          ※パターン名「基本診療時間」は編集と削除ができません。
        </div>
      </div>
      <div class="content">
        <show-data-list :displayKeys="displayKeys" :keys="keys" :data="data" />
      </div>
      <div class="setting-status">設定状況</div>
      <div class="setting-status-list">
        <div
          v-if="usedSpecialOpenTimesDates[0].length !== 0"
          class="setting-status-table"
        >
          <reservation-available-pattern-list-table
            :titleData="specialOpenTimesTitle"
            :bodyData="usedSpecialOpenTimesDates"
          />
        </div>
        <div
          v-if="usedReservationPurposes.length !== 0"
          class="setting-status-reservation-purpose"
        >
          <div
            v-for="(usedReservationPurpose, index) in usedReservationPurposes"
            :key="index"
            class="setting-status-table"
          >
            <reservation-available-pattern-list-table
              :titleData="reservationPurposesTitles"
              :bodyData="usedReservationPurpose"
            />
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      :type="type"
      @cancel="alertFlg = false"
      @decision="deleteReservationAvailablePattern"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList.vue'
import ReservationAvailablePatternListTable from '@/components/parts/organisms/ReservationAvailablePatternListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { timeFormatter } from '@/utils/convert_time'

export default {
  name: 'ReservationAvailablePatternShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    ReservationAvailablePatternListTable,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: ['パターン名', '設定時間'],
      keys: ['name', 'times'],
      data: {},
      specialOpenTimesTitle: ['診療日/設定箇所（例外設定）'],
      reservationPurposesTitles: [
        '来院目的名',
        '設定箇所(基本設定)',
        '設定箇所(例外設定)'
      ],
      weekDay: [
        '日曜日',
        '月曜日',
        '火曜日',
        '水曜日',
        '木曜日',
        '金曜日',
        '土曜日',
        '祝日'
      ],
      today: moment().format('YYYYMMDD'),
      alertFlg: false,
      waitFlg: false,
      title: '注意',
      buttons: ['閉じる', '削除'],
      type: 'alert',
      popupMessage: '削除してもよろしいですか？'
    }
  },

  computed: {
    ...mapGetters({
      getReservationAvailablePattern:
        'reservationAvailablePatterns/getDataById',
      reservationPurposes: 'reservationPurposes/getData',
      specialReservationAvailablePatterns:
        'specialReservationAvailablePatterns/getData',
      specialOpenTimes: 'specialOpenTimes/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    usedReservationPurposes() {
      const usedReservationPurposes = this.reservationPurposes.filter(o => {
        return (
          o.reservationAvailablePatternsIds.includes(this.data.id) ||
          this.specialReservationAvailablePatterns.filter(
            v =>
              v.reservationAvailablePatternId === this.data.id &&
              v.reservationPurposeId === o.id &&
              v.date >= this.today
          ).length !== 0
        )
      })
      const convertData = usedReservationPurposes.map(o => {
        let usedBasicWeek = ''
        if (o.reservationAvailablePatternsIds.includes(this.data.id)) {
          let index = o.reservationAvailablePatternsIds.indexOf(this.data.id)
          const indexes = []
          while (index != -1) {
            indexes.push(index)
            index = o.reservationAvailablePatternsIds.indexOf(
              this.data.id,
              index + 1
            )
          }
          usedBasicWeek = indexes.map(i => this.weekDay[i]).join(', ')
        }
        const usedSpecialDates = this.specialReservationAvailablePatterns
          .filter(
            v =>
              v.reservationAvailablePatternId === this.data.id &&
              v.reservationPurposeId === o.id &&
              v.date >= this.today
          )
          .sort((a, b) => a.date - b.date)
          .map(o => moment(o.date).format('YYYY/MM/DD'))
          .join(', ')
        return [o.name, usedBasicWeek, usedSpecialDates]
      })
      return convertData
    },
    usedSpecialOpenTimesDates() {
      const usedSpecialDates = this.specialOpenTimes
        .filter(
          o =>
            o.reservationAvailablePatternId === this.data.id &&
            this.today <= o.date
        )
        .sort((a, b) => a.date - b.date)
        .map(o => moment(o.date).format('YYYY/MM/DD'))
        .join(', ')
      return [usedSpecialDates]
    }
  },

  created() {
    const datum = this.getReservationAvailablePattern(this.id)
    const times =
      datum.closedDayFlg !== 1
        ? datum.baseTimeFlg === 1
          ? '基本診療時間を参照'
          : timeFormatter(datum.times)
        : '予約時間なし'
    this.data = {
      id: datum.id,
      name: datum.name,
      closedDayFlg: datum.closedDayFlg,
      times,
      baseTimeFlg: datum.baseTimeFlg
    }
  },

  methods: {
    backListPage() {
      this.$router.push({ name: 'reservation-available-patterns' })
    },
    openEditPage() {
      this.$router.push({
        name: 'reservation-available-pattern-edit',
        params: { id: this.id }
      })
    },
    initializeAlert() {
      this.alertFlg = false
      this.title = '注意'
      this.buttons = ['閉じる', '削除']
      this.type = 'alert'
      this.popupMessage = '削除してもよろしいですか？'
    },
    openAlert() {
      this.initializeAlert()
      this.alertFlg = true
    },
    async deleteReservationAvailablePattern() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'reservationAvailablePatterns/delete',
        this.id
      )
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.buttons = ['閉じる']
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'used in reservationPurpose') {
          this.popupMessage =
            '来院目的データの中で、カスタム設定の受付時間（基本設定）で現在設定されている為削除できません'
        } else if (result === 'used in specialOpenTime') {
          this.popupMessage =
            '共通設定/診療日画面の診療日（例外設定）で現在設定されている為削除できません'
        } else if (result === 'used in specialReservationAvailablePattern') {
          this.popupMessage =
            '来院目的データの中で、カスタム設定の受付時間（例外設定）で現在設定されている為削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.$router.push({ name: 'reservation-available-patterns' })
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-available-pattern-show {
  > .link-area {
    margin-left: -26px;
  }
  > .setting-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
  }
  > .setting-content {
    margin-top: 20px;
    margin-bottom: 24px;
    > .button-area {
      display: flex;
      justify-content: flex-end;
      > .button {
        margin-left: 20px;
      }
    }
    > .content-title {
      margin-top: 16px;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      > .content-title-text {
        margin-top: 17px;
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > .content {
      margin-top: 25px;
    }
    > .setting-status {
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-status-list {
      max-width: 1220px;
      > .setting-status-reservation-purpose {
        > .setting-status-table {
          margin: 30px 0;
        }
      }
      > .setting-status-table {
        margin: 30px 0;
      }
    }
  }
}
</style>
