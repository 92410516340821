<template>
  <div class="reservation-purpose-column-staff-area">
    <div class="register-area">
      登録する{{ titleName }}
      <reservation-purpose-list-box
        :data="registerData"
        :selectDataId="registerSelectData.id"
        @click="newHandleClick"
        @double-click="newHandleDoubleClick"
      />
    </div>
    <div class="register-columns-button-area">
      <base-button-tiny-white @click="up" class="tiny-button"
        >↑</base-button-tiny-white
      >
      <div class="button">
        <base-button-small-white :disabled="disabledAdd" @click="addData"
          >追加</base-button-small-white
        >
      </div>
      <div class="button cancel">
        <base-button-small-white :disabled="disabledCancel" @click="cancelData"
          >取消</base-button-small-white
        >
      </div>
      <base-button-tiny-white @click="down" class="tiny-button"
        >↓</base-button-tiny-white
      >
    </div>
    <div class="state-area">
      追加可能な{{ titleName }}
      <reservation-purpose-list-box
        :data="stateData"
        :selectDataId="stateSelectData.id"
        @click="handleClick"
        @double-click="handleDoubleClick"
      />
    </div>
  </div>
</template>

<script>
import ReservationPurposeListBox from '@/components/parts/molecules/ReservationPurposeListBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonTinyWhite from '@/components/parts/atoms/BaseButtonTinyWhite'
export default {
  name: 'ReservationPurposeColumnStaffArea',
  components: {
    ReservationPurposeListBox,
    BaseButtonSmallWhite,
    BaseButtonTinyWhite
  },

  props: {
    titleName: { type: String, default: '' },
    parentRegisterData: {
      type: Array,
      default: function() {
        return []
      }
    },
    parentStateData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },

  data() {
    return {
      registerData: this.parentRegisterData,
      registerSelectData: {},
      stateData: this.parentStateData,
      stateSelectData: {}
    }
  },

  computed: {
    disabledAdd() {
      return Object.keys(this.stateSelectData).length ? false : true
    },
    disabledCancel() {
      return Object.keys(this.registerSelectData).length ? false : true
    }
  },

  watch: {
    parentRegisterData: function(parentRegisterData) {
      this.registerData = parentRegisterData
      if (
        !this.registerData.map(v => v.id).includes(this.registerSelectData.id)
      ) {
        this.registerSelectData = {}
      }
    },
    parentStateData: function(parentStateData) {
      this.stateData = parentStateData
      if (!this.stateData.map(v => v.id).includes(this.stateSelectData.id)) {
        this.stateSelectData = {}
      }
    }
  },

  methods: {
    up() {
      if (Object.keys(this.registerSelectData).length) {
        const index = this.registerData.findIndex(
          v => v.id === this.registerSelectData.id
        )
        if (index != 0) {
          this.registerData.splice(
            index - 1,
            2,
            this.registerData[index],
            this.registerData[index - 1]
          )
          this.$emit('click', this.registerData, this.stateData)
        }
      } else if (Object.keys(this.stateSelectData).length) {
        const index = this.stateData.findIndex(
          v => v.id === this.stateSelectData.id
        )
        if (index != 0) this.stateSelectData = this.stateData[index - 1]
      }
    },
    down() {
      if (Object.keys(this.registerSelectData).length) {
        const index = this.registerData.findIndex(
          v => v.id === this.registerSelectData.id
        )
        if (
          this.registerSelectData.id !=
          this.registerData[this.registerData.length - 1].id
        ) {
          this.registerData.splice(
            index,
            2,
            this.registerData[index + 1],
            this.registerData[index]
          )
          this.$emit('click', this.registerData, this.stateData)
        }
      } else if (Object.keys(this.stateSelectData).length) {
        const index = this.stateData.findIndex(
          v => v.id === this.stateSelectData.id
        )
        if (
          this.stateSelectData.id !=
          this.stateData[this.stateData.length - 1].id
        )
          this.stateSelectData = this.stateData[index + 1]
      }
    },
    newHandleClick(datum) {
      this.registerSelectData = datum
      this.stateSelectData = {}
    },
    newHandleDoubleClick(datum) {
      this.registerSelectData = datum
      this.cancelData()
    },
    handleClick(datum) {
      this.stateSelectData = datum
      this.registerSelectData = {}
    },
    handleDoubleClick(datum) {
      this.stateSelectData = datum
      this.addData()
    },
    addData() {
      if (Object.keys(this.stateSelectData).length) {
        this.registerData.push(this.stateSelectData)
        this.stateData = this.stateData.filter(
          v => v.id != this.stateSelectData.id
        )
        this.$emit(
          'click',
          this.registerData,
          this.stateData,
          this.stateSelectData,
          'add'
        )
        this.registerSelectData = this.stateSelectData
        this.stateSelectData = {}
      }
    },
    cancelData() {
      if (Object.keys(this.registerSelectData).length) {
        this.registerData = this.registerData.filter(
          v => v.id != this.registerSelectData.id
        )
        this.stateData.push(this.registerSelectData)
        this.stateData.sort((a, b) => {
          return a.order - b.order
        })
        this.$emit(
          'click',
          this.registerData,
          this.stateData,
          this.registerSelectData,
          'cancel'
        )
        this.stateSelectData = this.registerSelectData
        this.registerSelectData = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-column-staff-area {
  display: flex;
  > .register-columns-button-area {
    margin: 34px 15px 0px;
    height: 200px;
    > .arrow {
      width: 33px;
      height: 33px;
      border-radius: 6px;
      border: solid 2px #{$light-grey};
      text-align: center;
      line-height: 33px;
    }
    > .button {
      margin-top: 20px;
      &.cancel {
        margin-top: 20px;
        margin-bottom: 24px;
      }
    }
    > .tiny-button {
      margin: 0 auto;
    }
  }
}
</style>
