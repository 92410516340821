<template>
  <div class="calendar-table-header">
    <div class="calendar-title">
      <button class="calendar-title-button" @click="movePrevMonth">
        <span class="calendar-title-button-arrow left">&lt;</span>
        <span class="calendar-title-button-text">前月</span>
      </button>
      <div class="calendar-title-date">
        {{ currentYear }}年 {{ currentMonth | addZero }}月
      </div>
      <button class="calendar-title-button" @click="moveNextMonth">
        <span class="calendar-title-button-text">翌月</span>
        <span class="calendar-title-button-arrow right">></span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarTableHeader',

  filters: {
    addZero: date => {
      const value = ('0' + String(date)).slice(-2)
      return value
    }
  },

  props: {
    currentYear: { type: Number },
    currentMonth: { type: Number }
  },

  methods: {
    movePrevMonth() {
      const month = this.currentMonth != 1 ? this.currentMonth - 1 : 12
      const year = month != 12 ? this.currentYear : this.currentYear - 1
      this.$emit('click', { currentYear: year, currentMonth: month })
    },
    moveNextMonth() {
      const month = this.currentMonth != 12 ? this.currentMonth + 1 : 1
      const year = month != 1 ? this.currentYear : this.currentYear + 1
      this.$emit('click', { currentYear: year, currentMonth: month })
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-table-header {
  > .calendar-title {
    display: flex;
    justify-content: space-between;
    margin: 0 7px 19px 7px;
    > .calendar-title-date {
      font-size: 20px;
      font-weight: bold;
    }
    > .calendar-title-button {
      width: 113px;
      height: 37px;
      border-radius: 6px;
      border: solid 2px #{$light-grey};
      background-color: white;
      cursor: pointer;
      > .calendar-title-button-text {
        vertical-align: middle;
        font-size: 15px;
      }
      > .calendar-title-button-arrow {
        color: #{$pumpkin};
        font-size: 20px;
        font-weight: bold;
        vertical-align: middle;
        &.left {
          margin-right: 16px;
        }
        &.right {
          margin-left: 19px;
        }
      }
    }
    > .calendar-title-button-dummy {
      width: 113px;
    }
  }
}
</style>
