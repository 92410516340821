<template>
  <div class="reservation-purpose-custom-area">
    <div class="content-title">
      <div class="title">受付時間パターン一覧</div>
      <base-button-small-white
        :styles="{ width: '190px' }"
        @click="openPatternsPage"
        >受付時間パターン一覧
      </base-button-small-white>
    </div>
    <list-table-show
      :header-data="headerData"
      :body-data="convertAllReservationAvailablePatterns"
      :property-data="properties"
      :table-styles="tableStyles"
      :body-styles="bodyStyles"
    />
    <div class="content-title">受付時間（基本設定）</div>
    <list-table-show
      :body-data="convertSelectReservationAvailablePatterns"
      :property-data="receptionTimeProperties"
      :table-styles="tableStyles"
      :selectDataList="reservationAvailablePatternsData"
      @input="inputReservationAvailablePatternId"
    />
    <div class="content-title">受付時間（例外設定）</div>
    <div class="content-title-description">
      <span class="red">基本設定より優先されます。</span><br />
      臨時休業の時など、基本設定に対し例外的に設定が必要になった時などに設定してください。<br />
      <div class="panel-color">
        ・例外日のパネルの色 ⇒
        <div class="color-plate exceptionday" />
      </div>
      <div class="panel-color">
        ・祝日のパネルの色 ⇒
        <div class="color-plate holiday" />
      </div>
    </div>
    <div class="calendar-area">
      <calendar-table
        :read-only-flg="!newAndEditFlg"
        :reservation-purpose="reservationPurpose"
        :open-times="openTimes"
        :special-reservation-available-patterns="
          filteredSpecialReservationAvailablePatterns
        "
        @input="inputSpecialReservationAvailablePatternId"
      />
    </div>
  </div>
</template>

<script>
import ListTableShow from '@/components/parts/organisms/ListTableShow.vue'
import CalendarTable from '@/components/parts/organisms/CalendarTable.vue'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import { mapGetters } from 'vuex'
import { timeFormatter } from '@/utils/convert_time'

export default {
  name: 'ReservationPurposeCustomArea',

  components: {
    ListTableShow,
    CalendarTable,
    BaseButtonSmallWhite
  },

  props: {
    reservationPurpose: {
      type: Object
    },
    specialReservationAvailablePatterns: {
      type: Array
    },
    newAndEditFlg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      headerData: ['パターン名', '設定時間'],
      properties: ['name', 'convertTimes'],
      receptionTimeProperties: ['weekDay', 'name', 'convertTimes'],
      tableStyles: {
        maxWidth: '1220px',
        textAlign: 'left'
      },
      bodyStyles: {
        height: '280px'
      }
    }
  },

  computed: {
    ...mapGetters({
      reservationAvailablePatterns: 'reservationAvailablePatterns/getData',
      getReservationAvailablePattern:
        'reservationAvailablePatterns/getDataById',
      openTimes: 'openTimes/getData'
    }),
    reservationAvailablePatternsData() {
      return this.newAndEditFlg
        ? this.$store.getters['reservationAvailablePatterns/selectData']
        : []
    },
    convertAllReservationAvailablePatterns() {
      return this.reservationAvailablePatterns.map(
        (reservationAvailablePattern, index) => {
          if (reservationAvailablePattern.closedDayFlg === 1) {
            return {
              ...reservationAvailablePattern,
              convertTimes: '予約時間なし'
            }
          } else if (reservationAvailablePattern.baseTimeFlg === 1) {
            return {
              ...reservationAvailablePattern,
              convertTimes: '基本診療時間を参照'
            }
          } else {
            return {
              ...reservationAvailablePattern,
              convertTimes: timeFormatter(reservationAvailablePattern.times)
            }
          }
        }
      )
    },
    convertSelectReservationAvailablePatterns() {
      const weekDay = [
        '日曜日',
        '月曜日',
        '火曜日',
        '水曜日',
        '木曜日',
        '金曜日',
        '土曜日',
        '祝日'
      ]
      return this.reservationPurpose.reservationAvailablePatternsIds.map(
        (reservationAvailablePatternsId, index) => {
          const reservationAvailablePattern = this.getReservationAvailablePattern(
            reservationAvailablePatternsId
          )
          const data = {
            id: reservationAvailablePattern.id,
            weekDay: weekDay[index],
            name: reservationAvailablePattern.name,
            convertTimes: timeFormatter(reservationAvailablePattern.times)
          }
          if (reservationAvailablePattern.closedDayFlg === 1) {
            data.convertTimes = '予約時間なし'
            return data
          } else if (reservationAvailablePattern.baseTimeFlg === 1) {
            // opneTimesのtypeカラム→'0:base,1:sunday,2:monday,3:tuesday,4:wednesday,5:thursday,6:friday,7:saturday,8:holiday'
            const openTime = this.openTimes.find(v => v.type === index + 1)
            data.convertTimes = this.makeBasicMedicalCareTime(openTime)
            return data
          } else {
            return data
          }
        }
      )
    },
    filteredSpecialReservationAvailablePatterns() {
      return this.specialReservationAvailablePatterns.filter(
        v =>
          v.reservationPurposeId === this.reservationPurpose.id && !v.deleteFlg
      )
    }
  },

  methods: {
    makeBasicMedicalCareTime(openTime) {
      if (openTime.closeFlg === 1) {
        return '休診日'
      } else if (openTime.lunchOpenFlg === 1) {
        return timeFormatter(openTime.start + openTime.end)
      } else {
        return timeFormatter([
          openTime.start + openTime.lunchStart,
          openTime.lunchEnd + openTime.end
        ])
      }
    },
    inputReservationAvailablePatternId(obj) {
      this.$emit('input-reservation-available-pattern-id', obj)
    },
    inputSpecialReservationAvailablePatternId(obj) {
      this.$emit('input-special-reservation-available-pattern', obj)
    },
    openPatternsPage() {
      this.$emit('open-patterns-page')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-purpose-custom-area {
  font-size: 15px;
  > .content-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 20px;
    margin: 40px 0px 30px;
    > .title {
      width: 240px;
    }
  }
  > .content-title-description {
    line-height: 1.54;
    > .red {
      color: #{$tomato};
    }
    > .panel-color {
      display: flex;
      margin-top: 10px;
      > .color-plate {
        width: 22px;
        height: 22px;
        margin-left: 6px;
        &.exceptionday {
          background-color: #{$pumpkin};
        }
        &.holiday {
          background-color: #{$light-purple};
        }
      }
    }
  }
  > .calendar-area {
    margin-top: 30px;
    max-width: 1230px;
  }
}
</style>
