<template>
  <div class="reservation-available-pattern-new">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="setting-title">受付時間パターン登録</div>
      <div class="setting-content">
        <div class="content-title">基本設定</div>
        <div class="content">
          <reservation-available-pattern-input-form
            :reservation-available-pattern="reservationAvailablePattern"
            @input-name="inputName"
            @input-start-time="inputStartTime"
            @input-end-time="inputEndTime"
            @add-pattern="addPattern"
            @del-pattern="delPattern"
          />
        </div>
        <div class="button-area">
          <base-button-medium-white class="button" @click="backListPage"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || !allValidFlg || waitFlg"
            @click="createReservationAvailablePattern"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <unsaved-leave-popup />
      <announce-popup
        v-if="alertFlg"
        @close="openListPage"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>

<script>
import ReservationAvailablePatternInputForm from '@/components/parts/organisms/ReservationAvailablePatternInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import {
  validateTimes,
  validTimePeriod,
  validTimePatterns
} from '@/utils/reservation_available_pattern'

export default {
  name: 'ReservationAvailablePatternNew',

  components: {
    ReservationAvailablePatternInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      reservationAvailablePattern: {},
      waitFlg: false,
      alertFlg: false,
      title: '完了',
      buttons: ['閉じる'],
      type: 'success',
      popupMessage: '新規登録しました'
    }
  },

  computed: {
    ...mapGetters({
      openTimes: 'openTimes/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    timeValidFlg() {
      return validateTimes(this.convertTimes)
    },
    periodValidFlg() {
      return validTimePeriod(this.convertTimes)
    },
    patternValidFlg() {
      return validTimePatterns(this.convertTimes)
    },
    allValidFlg() {
      return this.timeValidFlg && this.periodValidFlg && this.patternValidFlg
    },
    convertTimes() {
      return this.reservationAvailablePattern.times.map(v => {
        return {
          startTime: this.toTime(v.startTime),
          endTime: this.toTime(v.endTime)
        }
      })
    }
  },

  created() {
    this.reservationAvailablePattern = {
      name: '',
      times: [
        {
          key: 1,
          startTime: this.insertColon(this.baseOpenTimeDatum().start),
          endTime: this.insertColon(this.baseOpenTimeDatum().end)
        }
      ]
    }
    this.mixinInputData = this.reservationAvailablePattern
    this.mixinSetInitialData()
  },

  methods: {
    baseOpenTimeDatum() {
      // type=> 0:base,1:sunday,2:monday,3:tuesday,4:wednesday,5:thursday,6:friday,7:saturday,8:holiday
      return this.openTimes.find(v => v.type === 0)
    },
    insertColon(str) {
      return Number(str.slice(0, 2)) + ':' + str.slice(2, 4)
    },
    toTime(colonTime) {
      return ('0000' + colonTime.split(':').join('')).slice(-4)
    },
    inputName(val) {
      this.reservationAvailablePattern.name = val
    },
    inputStartTime(obj) {
      this.$set(
        this.reservationAvailablePattern.times[obj.targetIndex],
        'startTime',
        obj.val
      )
    },
    inputEndTime(obj) {
      this.$set(
        this.reservationAvailablePattern.times[obj.targetIndex],
        'endTime',
        obj.val
      )
    },
    addPattern() {
      const lastObject = this.reservationAvailablePattern.times[
        this.reservationAvailablePattern.times.length - 1
      ]
      this.reservationAvailablePattern.times.push({
        key: lastObject.key + 1,
        startTime: lastObject.endTime,
        endTime: this.insertColon(this.baseOpenTimeDatum().end)
      })
    },
    delPattern(targetIndex) {
      this.reservationAvailablePattern.times.splice(targetIndex, 1)
    },
    backListPage() {
      this.$router.push({ name: 'reservation-available-patterns' })
    },
    async createReservationAvailablePattern() {
      this.waitFlg = true
      const sendData = _.cloneDeep(this.reservationAvailablePattern)
      sendData.times = sendData.times
        .map(v => this.toTime(v.startTime) + this.toTime(v.endTime))
        .join(',')
      const result = await this.$store.dispatch(
        'reservationAvailablePatterns/create',
        sendData
      )
      if (result === true) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage = '登録に失敗しました'
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    openListPage() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ name: 'reservation-available-patterns' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-available-pattern-new {
  > .observer {
    > .setting-title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .setting-content {
      > .content-title {
        margin-top: 20px;
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .content {
        margin-top: 26px;
      }
      > .button-area {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        > .button {
          margin: 0 16px;
        }
      }
    }
  }
}
</style>
